import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#1a1a1a',
		secondary: '#6105f8',
		black: '#1a1a1a',
		white: '#F1F0ED',
		login_screen: {
			color: 'text-white',
			title: 'Vi er yne. Konsulentselskapet av utviklere, for utviklere!',
			statement: '',
		},
		blue: {
			700: '#6105f8',
			600: '#6105f8',
			500: '#6105f8',
			100: '#e9fffb',
		},
		red: {
			500: '#7C3647',
		},
		authimg: 'url("/yne-auth-bg.jpg")',
		authlogo: 'url("/yne-auth-logo.png")',
		logosymbol: 'url("/yne-symbol.svg")',
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
