import { atom, selector } from 'recoil';
import { getGlossary } from '../utils/api';
import { IGlossary } from '../types';

export const glossaryAtom = atom<IGlossary>({
	key: 'glossary',
	default: selector({
		key: 'glossary/default',
		get: async () => {
			return await getGlossary();
		},
	}),
});
