export type IDate = {
	key: string;
	type: string;
	start: string | null;
	end: string | null;
	data: Record<string, any>;
};

export const DATE_TYPES = {
	ALLOCATION_PERIOD: 'ALLOCATION_PERIOD',
};

export type IAllocationPeriodData = {
	allocation: number;
	status: 'hired' | 'potential' | 'extension';
};
