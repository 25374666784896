import { fetcher } from '@/utils/api';

export const getAllApps = async (type?: string) => {
	const qs = type ? `?type=${type}` : '';
	return await fetcher(`/apps${qs}`);
};

export const getAllConnectedApps = async () => {
	return await fetcher(`/apps/connected`);
};

export const toggleAppConnection = async (uid: string, settings?: Record<string, any>, space?: string) => {
	return await fetcher(`/apps/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({ settings, space }),
	});
};
