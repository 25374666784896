import { useEffect, useState } from 'react';
import { signOut } from '@/api/authentication';
import { useRouter } from 'next/router';
import { Pulsar } from '@uiball/loaders';

export const Loader = ({ className = '', firstTimer = 2500, secondTimer = 10000 }) => {
	const [loadingError1, setLoadingError1] = useState(false);
	const [loadingError2, setLoadingError2] = useState(false);
	const router = useRouter();

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoadingError1(true);
		}, firstTimer);
		const timer2 = setTimeout(() => {
			setLoadingError2(true);
		}, secondTimer);
		return () => {
			clearTimeout(timer);
			clearTimeout(timer2);
		};
	}, []);

	const onClickHandler = () => {
		signOut();

		router.push('/auth/login');
	};

	return (
		<div className={`mx-auto mt-4 space-x-2 flex flex-col items-center ${className}`}>
			<Pulsar size={40} speed={2} color="var(--color-blue-500)" />
			{!loadingError2 && loadingError1 && (
				<div className="flex flex-col">
					<div className="text-center">
						Seems like it taking us some time! <br /> Please dont leave maybe we&lsquo;re just a bit slow today
					</div>
				</div>
			)}
			{loadingError1 && loadingError2 && (
				<div className="flex flex-col">
					<div className="text-center ">
						Something unexpected happen! we are so sorry for the inconvince <br /> You can choose to wait and see if something will come
						or else use one of the options below
					</div>
					<div className="flex mt-2 justify-evenly">
						<div
							className="px-4 py-2 font-medium border-2 rounded-md cursor-pointer border-primary"
							// onClick={() => window.location.reload()}
						>
							Refresh
						</div>
						<div className="px-4 py-2 font-medium border-2 rounded-md cursor-pointer border-primary" onClick={onClickHandler}>
							Log out
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
