import { useState } from 'react';
import { MoneyInput } from '../inputs';

interface IPriceRange {
	readOnly?: boolean;
	label?: string;
	value: number[];
	onChange(prices: number[]): void;
}

export const PriceRange = ({ label, value = [0, 0], onChange }: IPriceRange) => {
	const [prices, setPrices] = useState<number[]>(value);

	const handleOnChange = (index: number) => (value: number) => {
		const clone = [...prices];
		clone[index] = value;
		setPrices(clone);
		onChange(clone);
	};

	return (
		<div>
			<div className="flex max-w-[550px]">
				<div className="flex-grow w-full">
					<p className="flex flex-col flex-wrap w-full text-xs font-medium text-gray-500">{label}</p>
					<MoneyInput className="field-input" value={prices[0]} onChange={handleOnChange(0)} />
				</div>
				<div className="flex items-center justify-center flex-shrink mt-[2rem] px-3">
					<span className="w-3 h-0.5 bg-black"></span>
				</div>
				<div className="flex-grow w-full">
					<p className="flex flex-col flex-wrap w-full text-xs font-medium text-gray-500 opacity-0">-</p>
					<MoneyInput className="field-input" value={prices[1]} onChange={handleOnChange(1)} />
				</div>
			</div>
		</div>
	);
};
