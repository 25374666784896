import { IApp } from '@/features/apps/types';
import { getAllApps, getAllConnectedApps } from '@/features/apps/utils/api';
import { atom, selector } from 'recoil';

export const appsAtom = atom<IApp[]>({
	key: 'appAtom',
	default: selector<IApp[]>({
		key: 'getAllConnectedApps',
		get: async () => {
			const apps = await getAllConnectedApps();
			if (!Array.isArray(apps)) {
				return [];
			}
			return apps;
		},
	}),
});

export const allAppsAtom = atom<IApp[]>({
	key: 'allAppAtom',
	default: selector<IApp[]>({
		key: 'getAllApps',
		get: async () => {
			const apps = await getAllApps();
			if (!Array.isArray(apps)) {
				return [];
			}
			return apps;
		},
	}),
});
