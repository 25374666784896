import { IApp } from '@/features/apps/types';
import { fetcher } from '@/utils/api';

export const SPACES_APP = 'ccd239ca-0ef1-43a0-a6fc-e3ecaecaab9e';
export const PREFERRED_SUPPLIERS_APP = '42d3fcda-8bf4-486c-9a36-5b63cb07f985';
export const NORWEGIAN_VAT_REGISTRY = '5d2d2be3-25d8-4234-b550-b7c95df0f527';
export const USERS_APP = '6b6e619b-a0e3-43f8-940b-71f5214f24a3';
export const CONTACT_PERSONS_APP = '6168dcf9-769b-4427-9001-9832fa50c132';
export const PREFERRED_CONTACT_PERSON_APP = 'e7028dd3-eced-4177-b3cb-262d1f5d7e4c';
export const TALENTS_APP = '956e7670-8b1a-4081-b971-6558afc8c416';
export const EMPLOYEE_APP = 'a2f8a5c8-52d2-4b68-ab21-26713e86da17';
export const EXTERNAL_TALENTS_APP = 'a9735370-a740-4ffc-b7ca-c3c6d0a9eee3';
export const TASK_TALENTS_APP = '146a7301-afb7-481f-9701-a23a31f9d99f';
export const CREATE_TASK_APP = '37ae0f5c-678d-4389-b748-a8e065e998f7';
export const PAYMENT_ADVICE_APP = 'fbb01f68-5e89-4c47-9a8b-0508da975ef4';
export const REGISTRATION_APP = 'b232b8e7-e7c6-4994-9583-e1b7b2f51481';
export const TAGS_APP = '92d5e2a3-7aaa-414f-8b7a-ad1c3046410c';
export const CVP_APP = '9c9f0b68-2e88-4f70-b9cb-820291fde10b';
export const COMPANIES_APP = '3d5b46d8-2f5d-42d3-9a43-be35e4e3ce82';
export const TRIPLETEX_APP = 'c69cdd29-d0bc-43ea-9ace-77be919614e1';

export const getAppByUid = async (uid: string): Promise<IApp<any>> => {
	return await fetcher(`/apps/${uid}`);
};

export const updateAppConfig = async (app: string, config: Record<string, any>) => {
	return await fetcher(`/apps/${app}/config`, {
		method: 'PUT',
		body: JSON.stringify({ config }),
	});
};

export const postAppData = async (app: string, data, path?: string) => {
	return await fetcher(`/apps/${app}/data${path ? path : ''}`, {
		method: 'POST',
		body: JSON.stringify({ ...data }),
	});
};

export const deleteAppData = async (app: string, data, path?: string) => {
	return await fetcher(`/apps/${app}/data${path ? path : ''}`, {
		method: 'DELETE',
		body: JSON.stringify({ ...data }),
	});
};

export const patchAppData = async (app: string, data, path?: string) => {
	return await fetcher(`/apps/${app}/data${path ? path : ''}`, {
		method: 'PATCH',
		body: JSON.stringify({ ...data }),
	});
};

export const putAppData = async (app: string, data, path?: string) => {
	return await fetcher(`/apps/${app}/data${path ? path : ''}`, {
		method: 'put',
		body: JSON.stringify({ ...data }),
	});
};

export const getAppData = async (app: string, path?: string) => {
	return await fetcher(`/apps/${app}/data${path ? path : ''}`);
};
