import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#01352c',
		secondary: '#0ADE90',
		black: '#000',
		white: '#ffffff',
		blue: {
			700: '#20c598',
			600: '#136a58',
			500: '#00a976',
			100: '#f4f1e8',
		},
		red: {
			500: '#ce3048',
		},
		authimg: 'url("/jyske-bg.jpg")',
		authlogo: 'url("/jb-logo.svg")',
		logosymbol: 'url("/jb-iris.svg")',
		login_screen: {
			color: 'text-white',
			title: 'Vi udfordrer og udvikler det danske bankmarked',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
