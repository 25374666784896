import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

interface IComboboxGeneric<T> {
	collection: T[];
	selected: T[] | T;
	placeholder?: string;
	renderItem(item: T, index?: number): JSX.Element;
	renderNotFound?({ query }: { query?: string }): JSX.Element;
	nameExtractor(item: T): string;
	onSelect(item: T[] | T): void;
	clearable?: boolean;
	onClear?(): void;
}

export const ComboboxGeneric = <T,>({
	collection,
	placeholder,
	nameExtractor,
	selected,
	onSelect,
	renderItem,
	clearable,
	onClear,
	renderNotFound,
	...props
}: IComboboxGeneric<T>) => {
	const [query, setQuery] = useState('');

	const filteredCollection =
		query === ''
			? collection
			: collection.filter(
					(item) =>
						(item as any)?.uid === query ||
						nameExtractor(item).toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
			  );

	return (
		<Combobox {...props} value={selected ?? null} onChange={onSelect}>
			<div className="field-input field-style relative w-full overflow-ellipsis">
				<Combobox.Input
					className="w-full text-ellipsis pl-2 pr-6 focus:outline-none"
					placeholder={placeholder ?? 'Search'}
					displayValue={nameExtractor}
					onChange={(event) => setQuery(event.target.value)}
				/>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
					<FontAwesomeIcon icon={faChevronDown} />
				</Combobox.Button>
				{clearable && selected && (
					<div
						onClick={() => onClear?.()}
						className="absolute right-5 top-1/2 flex -translate-y-1/2 cursor-pointer items-center pr-2 text-red-500"
					>
						<FontAwesomeIcon icon={faCircleXmark} />
					</div>
				)}
				<Transition
					as={Fragment}
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
					afterLeave={() => setQuery('')}
				>
					<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none">
						{filteredCollection.length === 0 && query !== '' ? renderNotFound?.({ query }) : filteredCollection.map(renderItem)}
					</Combobox.Options>
				</Transition>
			</div>
		</Combobox>
	);
};
