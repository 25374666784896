import { atom } from 'recoil';

export const allStatuses = atom({
	key: 'allStatuses',
	default: [
		{ name: 'Igangsat', step: 0, progress: 0 },
		{ name: 'Sendt ut tilbud', step: 1, triggerModal: 'dealModal', toStep: 2, progress: 33 },
		{ name: 'Tilbud levert til kunden', step: 2, toStep: 3, triggerModal: 'dealSentModal', progress: 66, color: 'rgb(255 212 124)' },
		{
			name: 'Vunnet',
			step: 3,
			triggerModal: 'wonModal',
			toStep: 3,
			progress: 100,
			color: 'rgb(132 231 132)',
			subs: [
				{ name: 'Mottatt kontrakt fra kunde', progress: 0, color: 'orange' },
				{ name: 'Sendt/mottatt kontrasignert kontrakt', progress: 50, color: 'orange' },
				{ name: 'Kontrakt arkivert', progress: 100, color: 'orange', triggerModal: 'saveContractModal' },
			],
		},
		{ name: 'Avlyst', step: 3, toStep: 3, progress: 0, color: 'rgb(249 124 124)' },
		{ name: 'Tapt', step: 3, toStep: 3, progress: 0, color: 'rgb(249 124 124)' },
		{ name: 'Ingen kandidater', step: 2, toStep: 2, progress: 0, color: 'rgb(249 124 124)' },
		{ name: 'Ingen egnede kandidater', step: 2, toStep: 2, progress: 0, color: 'rgb(249 124 124)' },
		{ name: 'Bestilling av konkurrent', step: 2, toStep: 2, progress: 0, color: 'rgb(249 124 124)' },
		{ name: 'Rakk ikke fristen', step: 2, toStep: 2, progress: 0, color: 'rgb(249 124 124)' },
	],
});
