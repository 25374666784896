import { Menu } from '@headlessui/react';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useCallback, useEffect, useRef, useState } from 'react';

interface IDropdownGeneric<T> {
	collection: ReadonlyArray<T>;
	renderItem({ item, index, isHighlighted }: { item: T; index: number; close(): void; isHighlighted?: boolean }): JSX.Element;
	button: JSX.Element;
	autoClose?: boolean;
	placeholder?: string;
	disabled?: boolean;
	open?: boolean;
	placement?: TooltipPlacement;
	onKeyDown?(e: KeyboardEvent, highlightedItem?: T): void;
	onOpenChange?(open: boolean): void;
	displaySearch?: boolean;
}

export const DropdownGeneric = <T,>({
	collection,
	renderItem,
	button,
	placeholder,
	autoClose = true,
	disabled,
	placement,
	open = false,
	onOpenChange,
	onKeyDown,
	displaySearch,
}: IDropdownGeneric<T>) => {
	const [popoverVisible, setPopoverVisible] = useState(open);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
	const [searchQuery, setSearchQuery] = useState<string>('');

	const hasLabels = displaySearch && Array.isArray(collection) && collection.some((item) => typeof item === 'object' && 'label' in item);

	const filteredCollection = hasLabels
		? collection.filter((item) => typeof item === 'object' && 'label' in item && item?.label?.toLowerCase().includes(searchQuery.toLowerCase()))
		: collection;

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
		setHighlightedIndex(-1);
	};

	const isEventListenerAdded = useRef(false);

	useEffect(() => {
		setPopoverVisible(open);
	}, [open]);

	useEffect(() => {
		if (!popoverVisible) {
			onOpenChange?.(popoverVisible);
		}
	}, [popoverVisible]);

	const handleKeyPressDropdown = useCallback(
		(e: KeyboardEvent) => {
			onKeyDown?.(e, filteredCollection[highlightedIndex]);
			if (e.code === 'ArrowDown') {
				e.preventDefault();
				setHighlightedIndex((prev) => (prev + 1) % filteredCollection.length);
			} else if (e.code === 'ArrowUp') {
				e.preventDefault();
				setHighlightedIndex((prev) => (prev - 1 + filteredCollection.length) % filteredCollection.length);
			}
		},
		[filteredCollection, highlightedIndex, onKeyDown]
	);

	const addEventListener = () => {
		if (!isEventListenerAdded.current) {
			document.addEventListener('keydown', handleKeyPressDropdown);
			isEventListenerAdded.current = true;
		}
	};

	const removeEventListener = () => {
		if (isEventListenerAdded.current) {
			document.removeEventListener('keydown', handleKeyPressDropdown);
			isEventListenerAdded.current = false;
		}
	};

	useEffect(() => {
		if (popoverVisible) {
			addEventListener();
		} else {
			removeEventListener();
		}
		return () => {
			removeEventListener();
		};
	}, [popoverVisible, addEventListener, removeEventListener]);

	const handleItemClick = (force: boolean = false) => {
		if (autoClose) {
			setPopoverVisible(false);
		}

		if (force) {
			setPopoverVisible(false);
		}
	};

	return (
		<Popover
			overlayClassName="custom-popover"
			open={popoverVisible}
			onOpenChange={setPopoverVisible}
			arrow={false}
			content={
				<Menu>
					<div className="max-h-[24rem] min-w-fit overflow-y-auto">
						{hasLabels && (
							<input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} className="w-full" />
						)}
						{filteredCollection?.length === 0 && (
							<div className="cursor-pointer p-2 text-center text-xs font-light italic text-gray-400 hover:text-blue-500">
								<p>{placeholder ?? 'No options available'}</p>
							</div>
						)}
						{filteredCollection?.map((item, index) => {
							const isHighlighted = index === highlightedIndex;
							return (
								<div
									key={index}
									onClick={() => {
										handleItemClick();
									}}
								>
									{renderItem({ item, index, close: () => handleItemClick(true), isHighlighted })}
								</div>
							);
						})}
					</div>
				</Menu>
			}
			trigger={disabled ? [] : 'click'}
			placement={placement ?? 'bottom'}
		>
			{button}
		</Popover>
	);
};
