import { fetcher } from '../../utils/api';

export const getSpace = async (uid: string) => {
	return await fetcher(`/spaces/${uid}`);
};

export const getSpaces = async () => {
	return await fetcher(`/spaces`);
};

export const getSubSpaces = async () => {
	return await fetcher(`/spaces/subs`);
};

export const getOrganisations = async () => {
	return await fetcher(`/organisations`);
};
