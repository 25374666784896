import { dayjsLocaleMapper } from '@/utils/helpers';
import da from 'antd/lib/date-picker/locale/da_DK';
import en from 'antd/lib/date-picker/locale/en_US';
import nb from 'antd/lib/date-picker/locale/nb_NO';
import sv from 'antd/lib/date-picker/locale/sv_SE';
import { atom, selector } from 'recoil';
const dateLocales = { da, sv, nb, en };

import { getSpaces } from '../../components/authentication/api';

export const spacesAtom = atom({
	key: 'spaces',
	default: selector({
		key: 'spaces/default',
		get: async () => {
			return await getSpaces();
		},
	}),
});

export const selectedSpaceAtom = atom({
	key: 'selectedSpace',
	default: null,
});

export const changeSpace = atom({
	key: 'changeSpace',
	default: false,
});

export const spaceSettings = selector({
	key: 'spaceSetting',
	get: ({ get }) => {
		const selectedSpace = get(selectedSpaceAtom);
		const locale = selectedSpace?.locale ?? 'en-US';
		const dateLocale = dateLocales[dayjsLocaleMapper[locale]];
		return {
			locale,
			dateLocale,
			currency: selectedSpace?.currency ?? 'EUR',
		};
	},
});

export const isSubspace = selector({
	key: 'isSubspace',
	get: ({ get }) => {
		const selectedSpace = get(selectedSpaceAtom);

		return selectedSpace?.organisation?.uid !== selectedSpace?.uid;
	},
});
