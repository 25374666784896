import * as FontAwesomeIconsRegular from '@fortawesome/pro-regular-svg-icons';
import * as FontAwesomeIconsSolid from '@fortawesome/pro-solid-svg-icons';
import * as FontAwesomeIconsDoutone from '@fortawesome/pro-duotone-svg-icons';
import * as FontAwesomeIconsLight from '@fortawesome/pro-light-svg-icons';
import * as FontAwesomeIconsBrands from '@fortawesome/free-brands-svg-icons';
import { IconType } from '../components/IconLoader';

const icons = {
	fontawesome: {
		regular: FontAwesomeIconsRegular,
		solid: FontAwesomeIconsSolid,
		duotone: FontAwesomeIconsDoutone,
		light: FontAwesomeIconsLight,
	},
};

export default {
	...icons,
	getIcons: (type?: IconType) => {
		return { ...icons.fontawesome?.[type ?? 'regular'], ...FontAwesomeIconsBrands };
	},
};
