import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { HTMLAttributes } from 'react';
import { Icon } from '../../../features/icons/components/IconLoader';

interface IDropdownItem extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
	title: string | JSX.Element;
	icon?: IconDefinition | string;
	selected?: boolean;
	className?: string;
}

export const DropdownItem = ({ title, className, icon, selected, ...props }: IDropdownItem) => {
	return (
		<div {...props} className={`flex items-center gap-2 text-sm px-2 py-1 rounded-md cursor-pointer hover:bg-neutral-100 m-1 ${className ?? ''}`}>
			{!!icon && selected ? <Icon icon={icon} className={`invisible ${selected ? '!visible' : ''}`} /> : null}
			{typeof title === 'string' ? <p>{title}</p> : title}
		</div>
	);
};
