import { getTaskQuickActions } from '@/features/templates/api';
import { atom, selector } from 'recoil';

const quickactionsSelector = selector({
	key: 'quickactionsSelector',
	get: async () => {
		return await getTaskQuickActions();
	},
});

export const quickactionsAtom = atom({
	key: 'quickactionsAtom',
	default: quickactionsSelector,
});
