// eslint-disable-next-line
export default () => {
	return {
		primary: '#FF4D00',
		secondary: '#0B0D15',
		black: '#0B0D15',
		white: '#fff',
		blue: {
			700: '#FF4D00',
			600: '#FF4D00',
			500: '#FF4D00',
			100: '#ffe9e1',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/bg-auth-gritera.jpg")',
		authlogo: 'url("/logo-gritera.svg")',
		logosymbol: 'url("/logo-gritera-symbol.svg")',
		login_screen: {
			color: 'text-black',
			title: 'Drømmehjemmet for de beste rådgiverne',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
