import { INotification } from '@/features/notifications/types';
import { getNotifications } from '@/features/notifications/utils/api';
import { atom, noWait, selector } from 'recoil';

export const notificationsAtom = atom<INotification[]>({
	key: 'notificationsAtom',
	default: selector({
		key: 'notificationsAtom/default',
		get: async () => {
			return (await getNotifications()) ?? [];
		},
	}),
});

export const unresolvedNotificationsAtom = selector({
	key: 'unresolvedNotificationsAtom',
	get: ({ get }) => {
		const { contents: notifications, state } = get(noWait(notificationsAtom));

		if (state !== 'hasValue') return [];

		return notifications.filter((n) => !n.resolved && n.type === 'PERSONAL');
	},
});

export const personalNotifications = selector({
	key: 'personalNotifications',
	get: ({ get }) => {
		const { contents: notifications, state } = get(noWait(notificationsAtom));

		if (state !== 'hasValue') return [];

		return notifications.filter((n) => n.type === 'PERSONAL');
	},
});
