import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { uploadFiles } from '../utils/api';
import { PlusIcon } from './Icons';
import { IFile } from '../types';

interface IUploadFiles {
	currentFolder?: string;
	onUpload?(): void;
	onComplete?(files: IFile[]): void;
	droppedFiles?: FileList;
}

export function UploadFiles({ currentFolder, onUpload, onComplete, droppedFiles }: IUploadFiles) {
	const [value, setValue] = useState<string>();

	useEffect(() => {
		if (droppedFiles && droppedFiles.length > 0) {
			handleUpload(droppedFiles);
		}
	}, [droppedFiles]);

	const handleUpload = async (files: FileList) => {
		onUpload?.();
		setValue('');
		if (files.length > 0) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('files', files[i]);
			}
			const { success, ...rest } = await uploadFiles(currentFolder, formData);
			if (success) {
				toast.success('Files has been uploaded');
				onComplete?.(rest.files);
			} else {
				toast.error('Something went wrong, try again');
			}
		}
	};
	return (
		<>
			<input className="hidden" type="file" id="upload-btn" value={value} onChange={(e) => handleUpload(e.target.files)} multiple />
			<label
				htmlFor="upload-btn"
				className="h-20 w-56 p-3 flex items-center justify-center rounded-lg border-dashed border-2 border-blue-500 cursor-pointer opacity-50 hover:opacity-100"
			>
				<PlusIcon className="w-8 h-8 text-blue-500" />
			</label>
		</>
	);
}
