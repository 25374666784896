import { appsAtom } from '@/atoms/apps';
import { isSubspace } from '@/atoms/spaces';
import { meAtom } from '@/atoms/users';
import {
	IconDefinition,
	faBarsStaggered,
	faBriefcase,
	faBuildings,
	faChartMixed,
	faFileContract,
	faGear,
	faStopwatch,
	faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import { Popover } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CSSProperties, HTMLAttributes, MouseEvent, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Icon } from '../../features/icons/components/IconLoader';
import { PagesAtom } from '../../features/page/atoms';
import { COMPANIES_APP } from '../../utils/api/apps';
import { Loader } from '../spinners/loader';
import ProjectsSideBar from './ProjectsSideBar';

const OFFSET_TOP = 112;

export const SideBar = () => {
	const [slider, setSlider] = useState<number>(OFFSET_TOP);
	const router = useRouter();
	const { pathname, asPath } = router;
	const { contents: apps, state } = useRecoilValueLoadable(appsAtom);
	const timer = useRef<NodeJS.Timeout>();
	const activeOffset = useRef<number>(OFFSET_TOP);
	const user: any = useRecoilValue(meAtom);
	const isSupplier = useRecoilValue(isSubspace);
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
	const sidebarRef = useRef(null);

	const handleClickOutside = (event) => {
		if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
			setIsSidebarOpen(false);
		}
	};

	const { contents: pages, state: statePage } = useRecoilValueLoadable(PagesAtom);

	useEffect(() => {
		const rect = getElement();
		if (rect) {
			setSlider(rect.top);
			activeOffset.current = rect.top;
		} else {
			setSlider(0);
		}
	}, [pages]);

	const onMouseEnter = (e: MouseEvent) => {
		const rect = e.currentTarget.getBoundingClientRect();
		clearTimeout(timer.current);
		setSlider(rect.top);
	};

	const onMouseLeave = () => {
		const rect = getElement();
		timer.current = setTimeout(() => {
			if (rect) {
				setSlider(activeOffset.current);
			} else {
				setSlider(0);
			}
		}, 400);
	};

	useEffect(() => {
		const activeTab = asPath.split('/')[1];
		const elem = document.getElementById(activeTab);

		if (elem) {
			const rect = elem.getBoundingClientRect();
			setSlider(rect.top);
			activeOffset.current = rect.top;
		} else {
			setSlider(0);
		}
	}, [asPath]);

	const getElement = () => {
		const activeTab = asPath.split('/')[1];
		const elem = document.getElementById(activeTab);
		return elem?.getBoundingClientRect();
	};

	const renderSideBarIcon = (id: string, icon: IconDefinition, active: boolean, href: string) => {
		return <SideBarIcon id={id} icon={icon} active={active} href={href} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
	};

	const renderPopoverSideBarIcon = (id: string, icon: IconDefinition, href: string, content: string) => {
		return (
			<Popover content={content} placement="right">
				<SideBarIcon
					id={id}
					icon={icon}
					active={false}
					href={href}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					className="cursor-pointer opacity-30"
				/>
			</Popover>
		);
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (statePage !== 'hasValue' || state !== 'hasValue') {
		return <Loader />;
	}

	let content: JSX.Element = <></>;
	if (user?.is_employee === false) {
		content = (
			<>
				<SideBarIcon
					id="projects"
					icon={faBriefcase}
					active={pathname.startsWith('/projects') || isSidebarOpen}
					onClick={() => setIsSidebarOpen(!isSidebarOpen)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				/>
				<div className="mb-12 mt-auto h-16 w-full">
					<SideBarIcon
						id="settings"
						icon={faGear}
						active={pathname.startsWith('/settings')}
						href={'/settings'}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					/>
				</div>
			</>
		);
	} else {
		content = (
			<>
				{slider !== 0 && (
					<div
						style={{ top: slider }}
						className={`absolute left-0 h-16 w-[6px] rounded-br-md rounded-tr-md bg-blue-500 transition-all group-hover:scale-100`}
					/>
				)}

				{(user?.space === 'a45e50ac-015c-471b-a9a5-ef18484bb7bd' ||
					user?.space === 'da72b49b-4215-4f36-82c1-1f4323e2b721' ||
					user?.space === '4b8d9331-6008-48ae-84ba-4d70ce819558') &&
					apps?.some((app) => app.tags.includes('Analytics')) &&
					renderSideBarIcon('analytics', faChartMixed, pathname.startsWith('/old_analytics'), '/old_analytics')}

				{isSupplier ? renderPopoverSideBarIcon('analytics', faChartMixed, '/sales', 'Analytics: Contact sales') : null}

				{state === 'hasValue' && pages?.map((page) => renderSideBarIcon(page.path.split('/')[1], page.icon, asPath === page.path, page.path))}

				{renderSideBarIcon('timeline', faBarsStaggered, pathname.startsWith('/timeline'), '/timeline')}

				{apps?.some((app) => app.tags.includes('People'))
					? renderSideBarIcon('people', faUsers, pathname.startsWith('/people'), '/people')
					: isSupplier
					  ? renderPopoverSideBarIcon('people', faUsers, '/sales', 'Manage your people: Contact sales')
					  : null}

				<SideBarIcon
					id="projects"
					icon={faBriefcase}
					active={pathname.startsWith('/projects') || isSidebarOpen}
					onClick={() => setIsSidebarOpen(!isSidebarOpen)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				/>

				{apps.some((app) => app.tags.includes('Time_Registration'))
					? renderSideBarIcon('time_registration', faStopwatch, pathname.startsWith('/time_registration'), '/time_registration')
					: isSupplier
					  ? renderPopoverSideBarIcon('time_registration', faStopwatch, '/sales', 'Time registration: Contact sales')
					  : null}

				{renderSideBarIcon('contracts', faFileContract, pathname.startsWith('/contracts'), '/contracts')}

				{apps.some((app) => app.uid === COMPANIES_APP)
					? renderSideBarIcon('companies', faBuildings, pathname.startsWith('/companies'), '/companies')
					: isSupplier
					  ? renderPopoverSideBarIcon('companies', faBuildings, '/sales', 'Manage companies and deals: Contact sales')
					  : null}

				<div className="mb-12 mt-auto h-16 w-full">
					<SideBarIcon
						id="settings"
						icon={faGear}
						active={pathname.startsWith('/settings')}
						href={'/settings'}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					/>
				</div>
			</>
		);
	}

	return (
		<aside ref={sidebarRef} className={`z-50 flex w-fit border-r bg-white transition-all`}>
			<div className="z-10 flex max-h-full w-16 flex-col items-center gap-4 border-r bg-inherit px-4 pt-12">{content}</div>
			<ProjectsSideBar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
		</aside>
	);
};

interface ISideBarIcon extends HTMLAttributes<HTMLAnchorElement> {
	icon: IconDefinition;
	active: boolean;
	href?: string;
	onClick?: () => void;
}

const SideBarIcon = ({ className, icon, active, href, onClick, ...props }: ISideBarIcon) => {
	const activeStyle = { '--fa-primary-color': 'var(--color-blue-500)', '--fa-secondary-color': 'var(--color-blue-500)' } as CSSProperties;
	const inactiveStyle = { '--fa-primary-color': '#79879B', '--fa-secondary-color': '#79879B' } as CSSProperties;

	if (!href) {
		return (
			<div
				className="h-16 w-full cursor-pointer"
				onClick={onClick}
				onMouseEnter={props.onMouseEnter as unknown as MouseEventHandler<HTMLDivElement>}
				onMouseLeave={props.onMouseLeave as unknown as MouseEventHandler<HTMLDivElement>}
			>
				<div className="group relative flex h-full w-full items-center justify-center">
					<Icon size="2x" icon={icon} className={className} style={active ? activeStyle : inactiveStyle} />
				</div>
			</div>
		);
	}
	return (
		<Link {...props} className="h-16 w-full" href={href} onClick={onClick}>
			<div className="group relative flex h-full w-full items-center justify-center">
				<Icon size="2x" icon={icon} className={className} style={active ? activeStyle : inactiveStyle} />
			</div>
		</Link>
	);
};

export default SideBar;
