import { ChangeEvent, KeyboardEvent, useState } from "react"
import { PlusIcon, } from "."
import { createFolder, } from "../utils/api"
import { toast } from "react-toastify"
import { FolderIcon } from "@/components/icons/folder"
import { IFolder } from "../types"

interface IAddFolder {
    currentFolder?: string
    onSave(folder: IFolder): void
}

export function AddFolder({ currentFolder, onSave }: IAddFolder) {
    const [folderName, setFolderName] = useState<string>()
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const handleFolderChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setFolderName(value)
    }

    const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            saveFolder()
        } else if (e.key === 'Escape') {
            setIsEditing(false)
        }
    }

    const saveFolder = async () => {
        if (!folderName) {
            setIsEditing(false)
            return
        }
        setIsEditing(false)
        setFolderName('')
        const { folder, didexist } = await createFolder(folderName, currentFolder)
        if (folder && !didexist) {
            toast.success('Folder has been created')
            onSave(folder)
        } else {
            toast.error('Folder with that name already exists')
        }
    }

    return (
        <>
            {isEditing ? (
                <div className='flex flex-shrink-0 w-72 h-full cursor-pointer p-1 group'>
                    <div className={`flex p-4 items-center rounded-lg border-2 border-white`}>
                        <FolderIcon className='w-6' />
                        <input
                            className="font-medium ml-2"
                            type="text"
                            value={folderName}
                            onChange={handleFolderChange}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            onFocus={(e) => e.target.select()}
                            onBlur={() => setIsEditing(false)}
                        />
                    </div>
                </div>
            ) : (
                <div onClick={() => setIsEditing(true)} className='flex flex-shrink-0 w-72 h-full cursor-pointer p-1 group'>
                    <div className='flex w-full h-full p-4 items-center justify-center rounded-lg border-dashed border-2 border-blue-500 cursor-pointer opacity-50 group-hover:opacity-100'>
                        <FolderIcon className='w-6 text-blue-500' />
                        <PlusIcon className="w-8 h-8 text-blue-500" />
                    </div>
                </div>
            )}
        </>

    )
}