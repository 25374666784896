import { fetcher } from '@/utils/api';
import { IExtendedTemplate, IGroup, ITab, ITemplate } from '../types';
import { IAccess } from '@/types/auth';
import { IField } from '@/features/fields/types';
import { IDataSource } from '@/features/datasources/types';

const APP = '52e7c717-d7fe-48fb-8916-61b8fea4ecbb';

export const getTaskTemplates = async (filter?: Partial<Record<keyof ITemplate, any>>): Promise<ITemplate[]> => {
	return await fetcher(`/apps/${APP}/data/templates?filter=${JSON.stringify(filter)}`);
};

export const getTaskTemplate = async (uid: string): Promise<IExtendedTemplate> => {
	return await fetcher(`/apps/${APP}/data/templates/${uid}`);
};

export const getTaskQuickActions = async (): Promise<ITemplate[]> => {
	return await fetcher(`/apps/${APP}/data/templates/quickactions`);
};

export const createTaskTemplate = async (body: {
	name: string;
	fields: IField[];
	tabs: ITab[];
	groups: IGroup[];
	relations: ITemplate['relations'];
	users: { user: string; access: IAccess }[];
	is_quickaction?: boolean;
	copy_from_parent?: boolean;
	is_minibid?: boolean;
}): Promise<ITemplate> => {
	return await fetcher(`/apps/${APP}/data/templates`, {
		method: 'POST',
		body: JSON.stringify(body),
	});
};

export const updateTaskTemplate = async (
	uid: string,
	body: {
		name: string;
		fields: IField[];
		tabs: ITab[];
		groups: IGroup[];
		relations: ITemplate['relations'];
		users: { user: string; access: IAccess }[];
		is_quickaction?: boolean;
		copy_from_parent?: boolean;
		is_minibid?: boolean;
	}
): Promise<ITemplate> => {
	return await fetcher(`/apps/${APP}/data/templates/${uid}`, {
		method: 'PUT',
		body: JSON.stringify(body),
	});
};

export const duplicateTaskTemplate = async (uid: string): Promise<ITemplate> => {
	return await fetcher(`/apps/${APP}/data/templates/${uid}`, {
		method: 'POST',
	});
};

export const deleteTaskTemplate = async (uid: string) => {
	return await fetcher(`/apps/${APP}/data/templates/${uid}`, {
		method: 'DELETE',
	});
};
