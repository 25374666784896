import { fetcher } from '../../../utils/api';
import { IFile, IFolder, IFolderContent } from '../types';

export const getAllFolders = async (): Promise<IFolder[]> => {
	return await fetcher(`/storage/folders`);
};

export const getSubfolders = async (uid?: string): Promise<Omit<IFolderContent, 'files'>> => {
	return await fetcher(`/storage/folders/${uid ? uid : 'root'}/subfolders`);
};

export const getFolderContent = async (uid?: string): Promise<IFolderContent> => {
	return await fetcher(`/storage/folders/${uid ? uid : 'root'}`);
};

export const getFileByUid = async (uid: string): Promise<IFile & { buffer: string }> => {
	return await fetcher(`/storage/files/${uid}`);
};

export const getSharedFiles = async (space_uid: string) => {
	return await fetcher(`/storage/files/shared?space=${space_uid}`);
};

export const createFolder = async (name: string, uid?: string): Promise<{ folder: IFolder; didexist: boolean }> => {
	return await fetcher('/storage/folders', {
		method: 'POST',
		body: JSON.stringify({ name, parent: uid }),
	});
};

export const zipFiles = async (folders: string[], files: string[]) => {
	return await fetcher('/storage/zip', {
		method: 'POST',
		body: JSON.stringify({ folders, files }),
	});
};

export const uploadFiles = async (uid: string, files: FormData): Promise<{ files: IFile[]; success: boolean }> => {
	return await fetcher(`/storage/folders/${uid}`, {
		method: 'POST',
		body: files,
	});
};

export const shareFiles = async (files: string[], space: string): Promise<{ uids: string[]; success: boolean }> => {
	return await fetcher(`/storage/files/share`, {
		method: 'POST',
		body: JSON.stringify({ files, space }),
	});
};

export const unshareFiles = async (files: string[], space: string): Promise<{ uids: string[]; success: boolean }> => {
	return await fetcher(`/storage/files/unshare`, {
		method: 'POST',
		body: JSON.stringify({ files, space }),
	});
};

export const moveFiles = async (files: string[], destination: string): Promise<{ success: boolean }> => {
	return await fetcher('/storage/files/move', {
		method: 'PUT',
		body: JSON.stringify({ files, destination }),
	});
};

export const moveFolders = async (folders: string[], destination: string): Promise<{ success: boolean }> => {
	return await fetcher('/storage/folders/move', {
		method: 'PUT',
		body: JSON.stringify({ folders, destination }),
	});
};

export const updateFoldername = async (uid: string, name: string): Promise<{ success: boolean }> => {
	return await fetcher(`/storage/folders/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({ name }),
	});
};

export const updateFilename = async (uid: string, filename: string): Promise<IFile> => {
	return await fetcher(`/storage/files/${uid}`, {
		method: 'PUT',
		body: JSON.stringify({ filename }),
	});
};

export const removeAttachments = async (attachments: string[]) => {
	return await fetcher(`/storage/attachments`, {
		method: 'DELETE',
		body: JSON.stringify({ attachments }),
	});
};

export const deleteFiles = async (files: string[]) => {
	if (files.length === 0) {
		return;
	}
	return await fetcher(`/storage/files`, {
		method: 'DELETE',
		body: JSON.stringify({ files }),
	});
};

export const deleteFolders = async (folders: string[]) => {
	if (folders.length === 0) {
		return;
	}
	return await fetcher(`/storage/folders`, {
		method: 'DELETE',
		body: JSON.stringify({ folders }),
	});
};
