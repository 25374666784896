import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { meAtom } from '../../atoms/users';
import { getItem } from '../../utils/browserStorage';

export const SignedOut = ({ children }) => {
	const [isLoggedOut, setIsLoggedOut] = useState(false);
	const user = useRecoilValue(meAtom);

	useEffect(() => {
		const hasCookie = !!getItem('_ft_a_');
		if (hasCookie === false) {
			setIsLoggedOut(true);
		}
	}, []);

	useEffect(() => {
		setIsLoggedOut(!user?.uid);
	}, [user]);

	if (isLoggedOut) return children;
	return null;
};
