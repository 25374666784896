import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#1a1a1a',
		secondary: '#0ADE90',
		black: '#000',
		white: '#fff',
		login_screen: {
			color: 'text-white',
			title: 'Choose the right future',
			statement: '',
		},
		blue: {
			700: '#2c2e80',
			600: '#2c2e80',
			500: '#44C0FF',
			100: '#c4c6fe',
		},
		red: {
			500: '#7C3647',
		},
		authimg: 'url("/right-bg-auth.jpg")',
		authlogo: 'url("/logo_Right_blue.svg")',
		logosymbol: 'url("/logo_R_blue.svg")',
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
