import { Popover } from 'antd';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Icon } from '../../../features/icons/components/IconLoader';

interface INumberInput extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	onChange?: (value: number) => void;
	label?: string;
	readOnly?: boolean;
	min?: number;
	max?: number;
	prefix?: string;
	suffix?: string;
}

export const NumberInput = React.forwardRef(
	({ onChange, value, prefix, suffix, label, className, min, max, ...props }: INumberInput, ref: React.RefObject<HTMLLabelElement>) => {
		const [inputValue, setInputValue] = useState<number>();
		const [error, setError] = useState<string>();

		useEffect(() => {
			setInputValue(value as number);
		}, [value]);

		const handleChange = (e) => {
			let result = e.target.value.replace(/\D\./g, '');

			if (!isNaN(result)) {
				if (result < min || result > max) {
					setError(`Value must be between ${min} and ${max}`);
					setInputValue(result);
					return;
				}
			} else {
				result = undefined;
			}

			setError(undefined);

			setInputValue(result);
			onChange?.(Number(result));
		};

		return (
			<div className="w-full">
				{label && <p className="pb-2 text-xs font-medium text-blue-500">{label}</p>}
				{props.readOnly ? (
					<p className={className}>{value}</p>
				) : (
					<div className="relative max-w-[550px]">
						<input
							ref={ref as any}
							{...props}
							type="text"
							onChange={handleChange}
							value={inputValue !== 0 && !inputValue ? '' : inputValue}
							className={`field-input ${className}`}
						/>
						{error && (
							<Popover content={<p className="text-xs text-red-500">{error}</p>} placement="right" trigger="hover">
								<div className="absolute right-1 top-1/2 -translate-y-1/2 cursor-help text-red-500">
									<Icon icon="faExclamationTriangle" size="sm" />
								</div>
							</Popover>
						)}

						{!!suffix && <p className="absolute right-0 top-1/2 -translate-y-1/2">{suffix}</p>}
					</div>
				)}
			</div>
		);
	}
);

NumberInput.displayName = 'NumberInput';
