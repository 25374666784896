import { getUsers } from '@/features/people/utils/api';
import { PREFERRED_SUPPLIERS_APP, getAppData } from '../apps';
import { fetcher } from '../index';
import { ITask } from 'pages/projects';

export const suppliersCollection = async () => {
	return await getAppData(PREFERRED_SUPPLIERS_APP);
};

export const usersCollection = async () => {
	const data = await getUsers();
	return data.filter((u: any) => u.type === 'INTERNAL');
};

export const subtasksCollection = async (reference?: string): Promise<ITask[]> => {
	return await fetcher(`/tasks${reference ? `/${reference}/subtasks` : ''}`);
}; 

export const siblingsCollection = async (uid) => {
	return await fetcher(`/tasks/${uid}/siblings`);
};

export const taskSuppliersCollection = async (reference) => {
	return await fetcher(`/collections/task_suppliers${reference ? `/${reference}` : ''}`);
};
