import Cookies from 'universal-cookie';

const getCookieItem = (name: string): string | null => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return null;
};

export const moveCookieToLocalStorage = (source: string, target: string) => {
	const cookie = getCookieItem(source);
	if (cookie) {
		setItem(target, cookie);
	} else {
		removeItem(target);
	}
};

let domain = null;

const debounce = function (func, wait) {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			func.apply(context, args);
		}, wait);
	};
};

const getDomain = () => {
	if (domain) {
		return domain;
	}

	domain = window.location.hostname
		.match(/([^\/?#]+)(?:[\/?#]|$)/i)[1]
		.split('.')
		.slice(-2)
		.join('.');
	return domain;
};

const setItem = (key: string, value: string) => {
	localStorage?.setItem(key, value);
};

const getItem = (key: string) => {
	return localStorage?.getItem(key);
};

const removeItem = (key: string) => {
	return localStorage?.removeItem(key);
};

const syncToken = () => {
	const authToken = getItem('_ft_a_');

	if (!authToken) {
		const token = getCookieItem('_ft_a_');
		if (token) {
			setItem('_ft_a_', token);
		} else {
			removeItem('_ft_a_');
		}
	}

	if (authToken) {
		new Cookies().set('_ft_ra_', authToken, { domain: `.${getDomain()}`, path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });
	} else {
		document.cookie = '_ft_ra_=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=' + `.${getDomain()}`;
	}
	document.cookie = '_ft_a_=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

const debouncedSyncToken = debounce(syncToken, 300);

const browserStorageMonitoring = () => {
	syncToken();
	window.addEventListener('mousemove', debouncedSyncToken);
	window.addEventListener('keydown', debouncedSyncToken);
	window.addEventListener('popstate', debouncedSyncToken);
	window.addEventListener('load', debouncedSyncToken);
	window.addEventListener('storage', debouncedSyncToken);

	return () => {
		window.removeEventListener('mousemove', debouncedSyncToken);
		window.removeEventListener('keydown', debouncedSyncToken);
		window.removeEventListener('popstate', debouncedSyncToken);
		window.removeEventListener('load', debouncedSyncToken);
		window.removeEventListener('storage', debouncedSyncToken);
	};
};

export { browserStorageMonitoring, setItem, getItem, removeItem };
