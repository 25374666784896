import { IFile } from '@/features/storage/types';
import { zipFiles } from '@/features/storage/utils/api';
import { fetcher } from '../index';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

export const listFiles = async (path: string) => {
	return await fetcher(`/storage?path=${path}`);
};

export const getAttachmentsOnNode = async (node: string) => {
	return await fetcher(`/storage/attachments/attach/${node}`);
};

export const addAttachmentsToNode = async (attachments: string[], node: string, fieldKey?: string) => {
	return await fetcher(`/storage/attachments/attach/${node}`, {
		method: 'POST',
		body: JSON.stringify({ attachments, fieldKey }),
	});
};

export const removeAttachmentsFromNode = async (attachments: string[], node: string) => {
	return await fetcher(`/storage/attachments/attach/${node}`, {
		method: 'DELETE',
		body: JSON.stringify({ attachments }),
	});
};

export const downloadFile = async (uid: string) => {
	window.location.href = `${API_URL}/storage/files/${uid}/download`;
};

export const downloadFiles = async (files: IFile[] | string[]) => {
	let uids = [];
	if (typeof files?.[0] === 'string') {
		uids = files;
	} else {
		uids = files.map((f) => f.uid);
	}
	const { base64 } = await zipFiles([], uids);
	const blob = await fetch(`data:application/zip;base64,${base64}`).then((res) => res.blob());
	const url = window.URL.createObjectURL(blob);
	window.location.href = url;
	setTimeout(() => window.URL.revokeObjectURL(url), 0);
};
