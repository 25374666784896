import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#253746',
		secondary: '#971B2F',
		black: '#1A1A1A',
		white: '#F1F0ED',
		login_screen: {
			color: 'text-white',
			title: 'Jobbing i et rettferdig konsulentselskap',
			statement: '',
		},
		blue: {
			700: '#971B2F',
			600: '#971B2F',
			500: '#971B2F',
			100: '#fae1e5',
		},
		red: {
			500: '#AB2328',
		},
		authimg: 'url("/vivende-auth-bg.jpg")',
		authlogo: 'url("/vivende-logo-color.svg")',
		logosymbol: 'url("/vivende_symbol.svg")',
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
