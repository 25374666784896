import React, { useEffect, useMemo, useState } from 'react';

import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';

import '@fortawesome/fontawesome-svg-core/styles.css';
import Head from 'next/head';
import { useRouter } from 'next/router';
import 'react-toastify/dist/ReactToastify.css';
import AuthenticationProvider from '../components/authentication';
import SideBar from '../components/sidebar/SideBar';
import '../styles/globals.css';
import '../styles/reactGrid.css';
import { disableDuplicateWarning } from '../utils/recoil';

// @ts-ignore
import { Toaster } from 'sonner';

global.console = disableDuplicateWarning(global.console);

import { Header } from '@/components/Header';
import { selectedTheme } from '../atoms/theme';
import { Loader } from '../components/spinners/loader';
import * as themes from '../themes';

import { Worker } from '@react-pdf-viewer/core';
import { ErrorBoundary } from '@sentry/nextjs';
import RedirectProvider from '../features/redirection/RedirectProvider';
import packageJson from '../package.json';
import Error500Page from './500';

import ChatWidget from '@/features/widgets/components/ChatWidget';
import { ProjectsProvider } from 'context/data-wrapper-context';
import { selectedSpaceAtom } from '../atoms/spaces';
import { NotificationsProvider } from '../context/notifications';
import PusherProvider from '../context/pusher-context';

type IWhiteLabel = {
	primary?: string;
	secondary?: string;
	black?: string;
	white?: string;
	blue?: {
		700?: string;
		600?: string;
		500?: string;
		100?: string;
	};
	red?: string;
	authimg?: string;
	authlogo?: string;
	logosymbol?: string;
};

const ThemeWrapper = ({ children }) => {
	const [whitelabel, setWhitelabel] = useRecoilState<IWhiteLabel>(selectedTheme);
	const [host, setHost] = useState('');
	const selectedSpace = useRecoilValue(selectedSpaceAtom);

	const whitelabelByHost = () => {
		if (themes?.[`_${host}`]) {
			setWhitelabel(themes?.[`_${host}`]());
		} else {
			setWhitelabel(themes?.[`_default`]() as any);
		}
	};

	useEffect(() => {
		whitelabelByHost();
	}, [host]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (host.length === 0) setHost(window.location.hostname.split('.')[0]);
		}
	});

	useEffect(() => {
		if (selectedSpace?.config?.theme) {
			setWhitelabel((prev) => ({ ...prev, ...(selectedSpace?.config?.theme || {}) }));
		}
	}, [selectedSpace]);

	return (
		<>
			{/* eslint-disable-next-line */}
			<style jsx global>
				{`
					:root {
						--color-primary: ${whitelabel?.primary};
						--color-secondary: ${whitelabel?.secondary};
						--color-black: ${whitelabel?.black};
						--color-white: ${whitelabel?.white};
						--color-blue-700: ${whitelabel?.blue?.['700'] || ''};
						--color-blue-600: ${whitelabel?.blue?.['600'] || ''};
						--color-blue-500: ${whitelabel?.blue?.['500'] || ''};
						--color-blue-100: ${whitelabel?.blue?.['100'] || ''};
						--color-red-500: ${whitelabel?.red?.['500'] || ''};
						--image-auth-img: ${whitelabel?.authimg};
						--image-auth-logo: ${whitelabel?.authlogo};
						--image-logo-symbol: ${whitelabel?.logosymbol};
					}
				`}
			</style>
			{children}
		</>
	);
};

function MyApp({ Component, pageProps }) {
	const router = useRouter();

	const pdfjsWorkerVersion: string = useMemo(() => packageJson.dependencies['pdfjs-dist'], []);

	const memoComponent = useMemo(() => {
		return <Component {...pageProps} key={router.asPath} />;
	}, [router.asPath, pageProps]);
	return (
		<ErrorBoundary fallback={<Error500Page />}>
			<RecoilRoot>
				<React.Suspense fallback={<Loader />}>
					<PusherProvider>
						<ThemeWrapper>
							<RedirectProvider>
								<Head>
									<meta name="robots" content="noindex, nofollow" />
									<title>Flextribe - Workforce Management System</title>
									<meta
										name="viewport"
										content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
									/>
								</Head>
								<AuthenticationProvider>
									{process.env.NODE_ENV !== 'development' && <ChatWidget />}
									<NotificationsProvider />
									<ProjectsProvider>
										<div className="flex h-screen w-screen flex-col overflow-hidden">
											<Header />
											<div className="flex h-[calc(100vh-4rem)] overflow-hidden">
												<SideBar />
												<main className="h-full w-full overflow-auto bg-gray-100">
													<Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsWorkerVersion}/build/pdf.worker.min.js`}>
														{memoComponent}
													</Worker>
												</main>
												<div id="modal-root"></div>
											</div>
										</div>
									</ProjectsProvider>
								</AuthenticationProvider>
							</RedirectProvider>
						</ThemeWrapper>
					</PusherProvider>
				</React.Suspense>
			</RecoilRoot>

			<Toaster visibleToasts={20} richColors position="top-right" closeButton />
		</ErrorBoundary>
	);
}

export default MyApp;
