// eslint-disable-next-line
export default () => {
	return {
		primary: '#11a754',
		secondary: '#11a754',
		black: '#000',
		white: '#fff',
		blue: {
			700: '#11a754',
			600: '#11a754',
			500: '#11a754',
			100: '#eef3f0',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/shortcut-bg-auth.jpg")',
		authlogo: 'url("/shortcut_logo.svg")',
		logosymbol: 'url("/shortcut_symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'Leading App Makers in Scandinavia',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
