import { useEffect, useState } from 'react';
import RedirectPage from '../../pages/r/[code]';
import { browserStorageMonitoring, getItem, moveCookieToLocalStorage, setItem } from '../../utils/browserStorage';
import { useRouter } from 'next/router';

export const REDIRECTION_HOST = 'r.flextribe.io';

export const RedirectProvider = ({ children }) => {
	const [isIframe, setIsIframe] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isOrigin, setIsOrigin] = useState(false);

	const router = useRouter();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			let isOrigin = false;
			if (REDIRECTION_HOST === window.location.hostname) {
				isOrigin = true;
			}

			if (isOrigin && !router.asPath.startsWith('/r')) {
				router.push('/r', undefined, { shallow: true });
			}

			if (window.self !== window.top) {
				setIsIframe(true);
			}

			setIsLoading(false);
			setIsOrigin(isOrigin);

			if (isOrigin) {
				moveCookieToLocalStorage('_ft_ra_', '_ft_a_');
			}
		}

		browserStorageMonitoring();
	}, []);

	const { pathname, query } = router;
	useEffect(() => {
		if (router.query['_f_rt_']) {
			try {
				setItem('_ft_a_', router.query['_f_rt_'] as string);
				const params = new URLSearchParams(query as any);
				params.delete('_f_rt_');
				router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
			} catch (error) {}
		}
	}, [router.query]);

	if (isLoading) return null;

	if (isIframe) return <>Trying to access Flextribe via iframe is not permitted! Contact flextribe for more information</>;

	if (isOrigin) return <RedirectPage />;

	return <>{children}</>;
};

export default RedirectProvider;
