// eslint-disable-next-line
export default () => {
	return {
		primary: '#101c29',
		secondary: '#d22328',
		black: '#101c29',
		white: '#fff',
		blue: {
			700: '#d22328',
			600: '#d22328',
			500: '#d22328',
			100: '#f9e6e7',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/bg-auth-systra.jpg")',
		authlogo: 'url("/systra-auth-logo.png")',
		logosymbol: 'url("/systra-symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'Vi kombinerer lokal fagekspertise med internasjonal kunnskap',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
