import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import XmarkIcon from './icons/x-mark';

const Modal = ({ show, onClose, children, maxWidth }) => {
	const [isBrowser, setIsBrowser] = useState(false);

	const ref = useRef();

	const handleCloseClick = (e) => {
		e.preventDefault();
		onClose();
	};

	useEffect(() => {
		window.addEventListener('keyup', (e) => {
			if (e.key === 'Escape') {
				onClose();
			}
		});

		setIsBrowser(true);
	}, []);

	const modalContent = show ? (
		<>
			<div className={`fixed z-40 left-0 right-0 w-10/12 p-4 mt-8 bg-white rounded-xl mx-auto ${maxWidth} h-auto max-h-4/5 `}>
				<div className="absolute flex text-lg right-10 flex-end">
					<div href="" onClick={handleCloseClick} className="cursor-pointer">
						<XmarkIcon className="w-8 h-8" strokeWidth={1} />
					</div>
				</div>
				<div ref={ref} className="h-full p-4 overflow-y-auto scrollbar-auto">
					{children}
				</div>
			</div>
			<div className="fixed top-0 left-0 right-0 z-30 w-screen h-screen bg-black opacity-50 backdrop-blur-sm align-center"></div>
		</>
	) : null;

	if (isBrowser) {
		return ReactDOM.createPortal(modalContent, document.getElementById('modal-root'));
	} else {
		return null;
	}
};

export default Modal;
