import { getIntroTemplateByTemplateUid } from '@/features/intro_templates/lib/api';
import { subtasksCollection } from '@/utils/api/collections';
import { getConfig } from '@/utils/api/tables';
import { groupBy } from 'lodash';
import { ITask } from 'pages/projects';
import { Dispatch, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getTaskTemplate } from '../features/templates/api';

interface IProjectProvider {
	tasks: Record<string, any>[];
	setTasks: Dispatch<SetStateAction<Record<string, any>[]>>;
	templates: any[];
	memoByOwner: any[];
	memoByShared: any[];
	isLoading: boolean;
	refetchTasks: () => void;
	refetchTemplates: () => void;
}

const ProjectContext = createContext<IProjectProvider>({} as IProjectProvider);

export function ProjectsProvider({ children }: { children: React.ReactNode }) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tasks, setTasks] = useState<Record<string, any>[]>([]);
	const [templates, setTemplates] = useState<any[]>([]);

	const fetchTasks = useCallback(async () => {
		const data = await subtasksCollection();
		return data;
	}, []);

	const fetchTemplates = useCallback(async (fetchedTasks: ITask[]) => {
		const tasksGroupedOnTemplate = groupBy(fetchedTasks, 'template.uid');
		const taskTemplates = Promise.all(
			Object.keys(tasksGroupedOnTemplate).map(async (templateUid) => {
				const taskTemplate = await getTaskTemplate(templateUid);
				const tasks = tasksGroupedOnTemplate[templateUid];
				const configs = await getConfig(templateUid);
				if (taskTemplate.is_minibid) {
					const introTemplate = await getIntroTemplateByTemplateUid(templateUid);
					return { ...taskTemplate, configs, ...configs.columns, tasks, deadlineField: introTemplate.deadline };
				}
				return { ...taskTemplate, configs, ...configs.columns, tasks };
			})
		);

		return taskTemplates;
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const fetchedTasks = await fetchTasks();
				setTasks(fetchedTasks);
				const templatesData = await fetchTemplates(fetchedTasks);
				setTemplates(templatesData);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [fetchTasks, fetchTemplates]);

	const refetchTasks = useCallback(() => {
		fetchTasks().then(setTasks).catch(console.error);
	}, [fetchTasks]);

	const refetchTemplates = useCallback(() => {
		fetchTasks()
			.then((fetchedTasks) => {
				fetchTemplates(fetchedTasks).then(setTemplates).catch(console.error);
			})
			.catch(console.error);
	}, [fetchTasks, fetchTemplates]);

	const groupByOwner = (data: any) => {
		const groupedData = new Map();

		data?.forEach((item: any) => {
			const ownerUid = item?._owner?.uid;
			const ownerName = item?._owner?.name;
			const ownerIcon = item?._owner?.icon;

			if (!!ownerUid && !groupedData.has(ownerUid)) {
				groupedData.set(ownerUid, {
					ownerUid,
					ownerName,
					ownerIcon,
					tasks: [],
				});
			}

			groupedData.get(ownerUid).tasks.push({ ...item });
		});
		return Array.from(groupedData.values());
	};

	const groupByTemplate = (data: any[]) => {
		const groupedData = new Map();

		data?.forEach((item) => {
			const templateUid = item.template.uid;
			const templateName = item.template.name;
			const template = item.template;
			const ownerUid = item?._owner?.uid;
			const ownerName = item?._owner?.name;
			const ownerIcon = item?._owner?.icon;

			if (!groupedData.has(templateUid)) {
				groupedData.set(templateUid, {
					ownerUid,
					ownerName,
					ownerIcon,
					templateUid,
					templateName,
					template,
					tasks: [],
				});
			}

			groupedData.get(templateUid).tasks.push({ ...item });
		});
		return Array.from(groupedData.values());
	};

	const groupTemplateByOwner = (data: any) => {
		const groupedData = new Map();

		data.forEach((item: any) => {
			const ownerUid = item.ownerUid;
			const ownerName = item.ownerName;
			const ownerIcon = item.ownerIcon;

			if (!groupedData.has(ownerUid)) {
				groupedData.set(ownerUid, {
					ownerUid,
					ownerName,
					ownerIcon,
					templates: [],
				});
			}

			groupedData.get(ownerUid).templates.push({ ...item });
		});

		return Array.from(groupedData.values());
	};

	const memoByOwner = useMemo(() => groupByOwner(tasks?.filter((task) => task?.is_minibid === true)), [tasks]);

	const memoByShared = useMemo(
		() => groupTemplateByOwner(groupByTemplate(tasks?.filter((task) => task.is_minibid === false && task._shared === true))),
		[tasks]
	);

	const projectProviderValue = {
		tasks,
		setTasks,
		memoByOwner,
		memoByShared,
		templates,
		isLoading,
		refetchTasks,
		refetchTemplates,
	};

	return <ProjectContext.Provider value={projectProviderValue}>{children}</ProjectContext.Provider>;
}

export function useProjects() {
	return useContext(ProjectContext);
}
