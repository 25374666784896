import { IAllProps } from '@tinymce/tinymce-react';
import { TextEditor } from '../TextEditor';

interface ITextArea extends IAllProps {
	readOnly?: boolean;
	onChange?: (event) => void;
	label?: string;
}

export const TextArea = ({ readOnly = false, value, onChange, label, ...props }: ITextArea) => {
	if (Array.isArray(value)) value = value.join('');

	return (
		<div>
			{label && <p className="mb-2 text-xs font-medium text-gray-500">{label}</p>}
			<TextEditor
				{...props}
				readOnly={readOnly}
				value={value}
				onEditorChange={(value) => {
					const event = {
						target: {
							value,
						},
					};
					onChange?.(event);
				}}
			/>
		</div>
	);
};
