import { meAtom } from '@/atoms/users';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';
import { useRecoilValueLoadable } from 'recoil';

declare global {
	interface Window {
		Tawk_API: {
			onLoad: (tx: any) => void;
			setAttributes: (attributes: Record<string, any>) => void;
		};
	}
}

const ChatWidget = () => {
	const tawkRef = useRef(null);
	const { contents: me, state } = useRecoilValueLoadable(meAtom);
	const visitorName = me?.firstname + ' ' + me?.lastname;

	const customStyle: any = {
		visibility: {
			desktop: {
				xOffset: 30,
				yOffset: 75,
				position: 'br',
			},

			mobile: {
				xOffset: 15,
				yOffset: 15,
				position: 'bl',
			},
		},
	};

	const onLoad = () =>
		tawkRef.current?.setAttributes({
			name: visitorName,
			email: me?.email,
			phone: me?.phone,
		});

	return (
		<TawkMessengerReact
			propertyId={process.env.TAWK_PROPERTY_ID}
			widgetId={process.env.TAWK_WIDGET_ID}
			ref={tawkRef}
			onLoad={onLoad}
			customStyle={customStyle}
		/>
	);
};

export default ChatWidget;
