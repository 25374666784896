import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#002f6c',
		secondary: '#0ADE90',
		black: '#333',
		white: '#fff',
		blue: {
			700: '#1485E2',
			600: '#1860be',
			500: '#ffd100',
			100: '#f1f6fc',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/noble-bg.jpg")',
		authlogo: 'url("/noble-auth-logo.png")',
		logosymbol: 'url("/noble-symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'World-class offshore drilling company focusing on operational performance and safety',
			statement:
				'Noble operates one of the youngest and most advanced fleets in the offshore drilling industry with a combined track record of industry-leading utilization.',
		},
	};
};
