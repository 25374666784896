import { IField } from '@/features/fields/types';
import { glossaryAtom } from '@/features/glossary/atoms';
import { IGlossary } from '@/features/glossary/types';
import { getGlossary } from '@/features/glossary/utils/api';
import { getGlossaryDate, parseFieldDate } from '@/utils/helpers';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { useFields } from './useFields';

export type IGlossaryValues = {
	_name: string;
	_description: string;
	_reference: string;
	_allocation: string | number;
	_start_date: string;
	_end_date: string;
	_extensions: string[] | string[][];
	[key: string]: any;
};

export const useGlossary = (payload: Record<string, any> | Record<string, any>[], uid?: string) => {
	const [glossary, setGlossary] = useState<IGlossary>({ uid: '', mappings: {}, created: '' });
	const { fields: allFields } = useFields();
	const { contents: _glossary, state } = useRecoilValueLoadable(glossaryAtom);
	const defaultMappings = allFields.reduce((acc, f) => {
		acc[f.key] = [];
		return acc;
	}, {});
	const allMappings = { ...defaultMappings, ...glossary.mappings };
	let parsedValues: IGlossaryValues | IGlossaryValues[];

	useEffect(() => {
		fetchGlossary();
	}, [uid, state]);

	const fetchGlossary = async () => {
		if (!uid) {
			setGlossary(_glossary);
			return;
		}
		const glossary = await getGlossary(uid);
		setGlossary(glossary);
	};

	const parsePayload = (payload: Record<string, any>) => {
		return Object.entries(allMappings).reduce(
			(acc, [sourceKey, fields]) => {
				let value = payload?.[sourceKey];
				if (!!value && !(fields as IField[])?.length) {
					if (sourceKey === '_start_date' || sourceKey === '_end_date') {
						acc[sourceKey] = getGlossaryDate(value, sourceKey === '_end_date');
					} else {
						acc[sourceKey] = value;
					}
					return acc;
				}

				let temp = {};

				for (const field of fields as IField[]) {
					value = payload?.[field.key];

					if (temp?.hasOwnProperty(sourceKey) && !isEmpty(temp?.[sourceKey])) continue;

					if (['date', 'date_range', 'multi_date_range'].includes(field.type)) {
						const sourceField = allFields.find((f) => f.key === sourceKey);
						if (!!sourceField) {
							temp[sourceKey] = parseFieldDate(value, sourceField.type);
							continue;
						}
					}
					temp[sourceKey] = value;
				}
				acc = { ...acc, ...temp };
				return acc;
			},
			{ _name: '', _description: '', _reference: '', _allocation: '', _start_date: '', _end_date: '', _extensions: [] }
		);
	};

	parsedValues = useMemo(() => {
		if (Array.isArray(payload)) {
			return payload.map((p) => ({ ...p, ...parsePayload(p) }));
		} else {
			return { ...payload, ...parsePayload(payload) };
		}
	}, [payload, allMappings]);

	return { values: parsedValues };
};
