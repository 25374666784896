export const formatTypeValues = (value, type) => {
	switch (type) {
		case 'money':
		case 'price_range':
			return { value: value?.value ?? value, currency: value?.currency, locale: value?.locale };
		default:
			return value;
	}
};

export const roundToThreeDecimals = (value: number) => {
	return Math.round(value * 1000) / 1000;
};
