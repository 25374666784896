import { selectedTheme } from '@/atoms/theme';
import { meAtom } from '@/atoms/users';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useRouter } from 'next/router';
import { PhoneInput, TextInput } from '../../../shared/components/fields';
import { useSWRConfig } from '../../../utils/api';
import { updateNodeContent } from '../../../utils/api/nodes';

type ITheme = {
	login_screen?: {
		color?: string;
		title?: string;
		statement?: string;
	};
};

function SignUpPage() {
	const me = useRecoilValue(meAtom);
	const [user, setUser] = useState(me);
	const { push } = useRouter();
	const theme = useRecoilValue(selectedTheme) as ITheme;
	const { mutate } = useSWRConfig();
	const [validPhone, setValidPhone] = useState(false);

	const [state, setState] = useState({
		firstname: { value: '', error: '' },
		lastname: { value: '', error: '' },
		phone: { value: '', error: '' },
	});
	const handleClick = async () => {
		let error = false;

		const phone = user?.phone?.toString();

		if (!user?.firstname || !user?.lastname || phone?.length === undefined || !validPhone) {
			error = true;
		} else {
			await updateNodeContent(user?.uid, { firstname: user?.firstname, lastname: user?.lastname, phone: user?.phone });
			await mutate('/me');
			window.location.assign('/');
		}

		if (error === false) {
			push('/');
		}
	};

	const handleChangeUser = (key) => (e) => {
		if (e?.target) e = e.target.value;

		setUser((user) => ({
			...user,
			[key]: e,
		}));
	};

	return (
		<div>
			<div className="fixed bg-fixed z-[100] login w-full h-full px-4 py-16 bg-authImg"></div>
			<div className="flex absolute w-full z-[101] top-[50%] translate-y-[-50%]">
				<div
					className={`${
						theme?.login_screen?.color ?? 'text-primary'
					} 2xl:w-9/12 sm:w-7/12 lg:w-8/12 2xl:pr-60 2xl:pt-80 2xl:pl-60 lg:pl-10 sm:pt-40 sm:pl-4 max-sm:hidden`}
				>
					<p className="font-light lg:text-6xl sm:text-4xl">{theme?.login_screen?.title}</p>
					<p className="pt-8 text-xl lg:pr-40 md:pr-20">{theme?.login_screen?.statement}</p>
				</div>
				<div className="items-end justify-center ml-auto 2xl:w-3/12 lg:w-4/12 2xl:mr-40 lg:mr-10 md:mr-4 md:w-5/12 max-sm:w-full max-sm:mr-4 max-sm:ml-4">
					<div className="w-full p-10 mt-16 mb-4 bg-white shadow rounded-xl">
						<div className={`relative h-12 pb-8 mx-auto bg-center bg-no-repeat w-60 bg-authLogo`}></div>
						<p className="py-2 mt-4 text-xl font-medium text-center">Finish your account</p>
						<div className="flex items-center justify-between w-full pt-5">
							<hr className="w-full bg-gray-400" />
							<p className="px-4 text-[10px] font-medium leading-4 text-gray-400 uppercase select-none">Personal</p>
							<hr className="w-full bg-gray-400 " />
						</div>
						<div className="w-full mt-4">
							<div className="w-full">
								<p className="pb-2 text-xs font-medium text-gray-500">Email</p>
								<div className="">{user.email}</div>
							</div>
							<div className="flex mt-4 space-x-4">
								<div className="">
									<TextInput label="First name" value={user?.firstname} onChange={handleChangeUser('firstname')} />
									{!user?.firstname?.length && <p className="text-xs italic text-red-500">*Missing</p>}
								</div>
								<div className="">
									<TextInput label="Last name" value={user?.lastname} onChange={handleChangeUser('lastname')} />
									{!user?.lastname?.length && <p className="text-xs italic text-red-500">*Missing</p>}
								</div>
							</div>
							<div className="mt-4">
							<PhoneInput
									label="Phone number"
									value={user?.phone?.toString()}
									onChange={handleChangeUser('phone')}
									isValid={validPhone}
									setIsValid={setValidPhone}
								/>								{user?.phone?.toString().length < 4 && <p className="text-xs italic text-red-500">*Missing</p>}
							</div>
						</div>
						<div className="mt-8">
							<button
								role="button"
								className="w-full py-4 text-lg font-medium leading-none text-white bg-blue-600 border rounded-xl hover:opacity-80"
								onClick={handleClick}
							>
								Finish signup
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUpPage;
