import { useEffect, useState } from 'react';
import { DropdownGeneric } from './DropdownGeneric';
import { DropdownItem } from '../items/DropdownItem';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { BasicButton } from '@/components/buttons/BasicButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { siblingsCollection } from '@/utils/api/collections';
import { IGlossaryValues, useGlossary } from 'hooks/useGlossary';

interface IExtendedTask {
	value: { extended_task_uid: string; continuation_type: string };
	task: string;
	label?: string;
	placeholder: string;
	onChange({ extended_task_uid, continuation_type }: { extended_task_uid: string; continuation_type: string }): void;
}

export enum CONTINUATION {
	INTERNAL,
	EXTERNAL,
}

export const ExtendedTask = ({ value, task, label, placeholder, onChange }: IExtendedTask) => {
	const [parents, setParents] = useState<any[]>([]);
	const extendedTask = parents.find((p) => p.uid === value?.extended_task_uid);
	const { values } = useGlossary(parents) as { values: IGlossaryValues[] };

	useEffect(() => {
		fetchParentTasks();
	}, [task]);

	const fetchParentTasks = async () => {
		const tasks = await siblingsCollection(task);
		setParents(tasks);
	};

	const toggleContinuation = (key: CONTINUATION) => () => {
		const continuation_type = value?.continuation_type === CONTINUATION[key] ? null : CONTINUATION[key];
		onChange({ ...value, continuation_type });
	};

	const toggleTask = (task: any) => {
		const extended_task_uid = value?.extended_task_uid === task.uid ? null : task.uid;
		onChange({ ...value, extended_task_uid });
	};

	const getDropdownText = () => {
		if (extendedTask) {
			return extendedTask.name ?? extendedTask.uid;
		}
		if (!parents.length) {
			return 'No tasks available';
		}
		return placeholder;
	};

	return (
		<div>
			{label && <p className="text-xs font-medium text-gray-500 mb-1">{label}</p>}
			<DropdownGeneric
				collection={values}
				renderItem={({ item }: { item: any }) => (
					<DropdownItem
						title={item._name ?? item.uid}
						selected={item.uid === extendedTask?.uid}
						icon={faCheck}
						onClick={() => toggleTask(item)}
					/>
				)}
				button={
					<div className="flex items-center justify-between gap-4 border-b-2 focus:outline-blue-500">
						<p className={`${!!extendedTask ? '' : 'text-gray-500'} text-sm`}>{getDropdownText()}</p>
						<FontAwesomeIcon icon={faChevronDown} />
					</div>
				}
			/>
			{value?.extended_task_uid && (
				<div className="flex flex-col gap-2 mt-4">
					<BasicButton
						className={`w-full ${
							value?.continuation_type === CONTINUATION[CONTINUATION.INTERNAL] ? '!bg-blue-500 !text-white !border-none' : ''
						}`}
						onClick={toggleContinuation(CONTINUATION.INTERNAL)}
					>
						Continuation of own consultant
					</BasicButton>
					<BasicButton
						className={`w-full ${
							value?.continuation_type === CONTINUATION[CONTINUATION.EXTERNAL] ? '!bg-blue-500 !text-white !border-none' : ''
						}`}
						onClick={toggleContinuation(CONTINUATION.EXTERNAL)}
					>
						Continuation competitor
					</BasicButton>
				</div>
			)}
		</div>
	);
};
