import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IGenericDateRangePicker = RangePickerProps & {
	label?: string;
	readOnly?: boolean;
	onChange(date: dayjs.Dayjs[] | null): void;
};

export const GenericDateRangePicker = ({ label, value, onChange, onBlur, className, readOnly = false, ...props }: IGenericDateRangePicker) => {
	const [dateValue, setDateValue] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(null); // Initialize with null

	useEffect(() => {
		if (!!value && Array.isArray(value)) {
			setDateValue(value.map((d) => dayjs(d)) as [dayjs.Dayjs, dayjs.Dayjs]);
		}
	}, [value]);

	const onChangeHandler = (date: [dayjs.Dayjs, dayjs.Dayjs] | null, dateString: [string, string]) => {
		setDateValue(date);
		onChange?.(date);
	};

	let format = 'DD/MM/YYYY';
	if (typeof props.format === 'string') {
		format = props.format;
	}

	let placeholder: [string, string] = props?.placeholder ?? ['Start date', 'End date'];

	if (props?.format && typeof props.format === 'string') {
		placeholder = [props.format, props.format];
	}

	// Set a specific placeholder when both date values are null
	if (!dateValue || (!dateValue[0] && !dateValue[1])) {
		placeholder = ['Start date', 'End date'];
	}

	return (
		<div className="mt-2">
			{label && <p className="pb-2 text-xs font-medium text-blue-500">{label}</p>}
			<div className="max-w-[550px] h-10">
				{readOnly ? (
					<>{dateValue ? dateValue.map((d) => d.format(format)) : 'No dates selected!'}</>
				) : (
					<DatePicker.RangePicker
						{...props}
						format={format}
						value={dateValue}
						className={'w-full h-10 text-lg'}
						onChange={onChangeHandler}
						placeholder={placeholder} // Use the dynamically set placeholder
						suffixIcon={<FontAwesomeIcon icon={faCalendar} color="#ccc" />}
					/>
				)}
			</div>
		</div>
	);
};
