import { useState, useRef, ChangeEvent, useEffect } from 'react';
import { toast } from 'sonner';
import { removeAttachments, uploadFiles } from '@/features/storage/utils/api';
import FolderSelector from '@/components/storage/FolderSelector';
import { IFile, IFolder } from '@/features/storage/types';
import { FolderIcon } from '@/icons/folder';
import { Ring } from '@uiball/loaders';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload, faFiles, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { BasicButton } from '@/components/buttons/BasicButton';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1024 * 1024 * 25;
const API_URL = process.env.NEXT_PUBLIC_API_URL;

interface IMultiFileUpload {
	maxFileSizeInBytes?: number;
	label?: string;
	files?: IFile[];
	buttonText?: string;
	reference?: string;
	destination?: string;
	onSuccess?(files: IFile[]): void;
	onRemove?(file: IFile): void;
}

export const MultiFileUpload = ({
	maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
	label,
	files,
	reference,
	buttonText = 'Upload files',
	destination,
	onSuccess,
	onRemove,
}: IMultiFileUpload) => {
	const [value, setValue] = useState<string>('');
	const [_files, setFiles] = useState<IFile[]>([]);
	const [destFolder, setDestFolder] = useState<IFolder>();
	const [showSelector, setShowSelector] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const fileInputField = useRef<HTMLInputElement>(null);
	const timeout = useRef<NodeJS.Timeout>();
	const filesBackup = useRef<IFile[]>([]);

	useEffect(() => {
		if (files?.length) {
			setFiles(files);
			filesBackup.current = files;
		}
	}, [files]);

	const handleUploadBtnClick = () => {
		if (!destFolder) {
			setShowSelector(true);
		} else {
			fileInputField.current.click();
		}
	};

	const onFolderSelect = (folder: IFolder) => {
		setShowSelector(false);
		setDestFolder(folder);
	};

	const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			setValue('');
			setLoading(true);
			let payloadSize = 0;
			const { files } = e.target;
			if (files.length > 0) {
				const formData = new FormData();
				for (let i = 0; i < files.length; i++) {
					const file = files[i];
					payloadSize += file.size;
					formData.append('files', file);
				}
				if (payloadSize > maxFileSizeInBytes) {
					toast.error(`Max filesize allowed is ${maxFileSizeInBytes / 1000000} MB.`);
					return;
				}
				const res = await uploadFiles(destFolder.uid, formData);

				if (res.success) {
					toast.success('Files has been uploaded');
					onSuccess?.(res.files);
				} else {
					toast.error('Something went wrong, try again');
				}
			}
		} catch (error) {
			toast.error('Something went wrong, try again');
		} finally {
			setLoading(false);
		}
	};

	const _onRemove = async (file: IFile) => {
		const filtered = _files.filter((f) => f?.attachment_id !== file.attachment_id || f.uid !== file.uid);
		setFiles(filtered);

		toast.success(
			<div className="flex flex-col gap-2">
				<p>Files has been removed</p>
				<button
					className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
					onClick={undo}
				>
					Undo
				</button>
			</div>
		);

		timeout.current = setTimeout(async () => {
			if (file?.attachment_id) {
				await removeAttachments([file?.attachment_id]);
			}
			onSuccess?.(filtered);
			onRemove?.(file);
			filesBackup.current = filtered;
		}, 3500);
	};

	const undo = () => {
		setFiles(filesBackup.current);
		onSuccess?.(filesBackup.current);
		clearTimeout(timeout.current);
	};

	return (
		<div>
			<FolderSelector open={showSelector} close={() => setShowSelector(false)} onSelect={onFolderSelect} destination={destination} />
			<div className="flex w-full items-center justify-between">
				{label && <p className="text-xs font-medium text-gray-500">{label}</p>}
				{!!destFolder && (
					<div onClick={() => setShowSelector(true)} className={`flex items-center gap-1 group cursor-pointer`}>
						<FolderIcon className="w-4 group-hover:text-blue-500" />
						<p className="text-xs font-medium text-gray-500 group-hover:text-blue-500 ">{destFolder.name}</p>
					</div>
				)}
			</div>
			<BasicButton className="flex items-center justify-center w-full mt-2" onClick={handleUploadBtnClick}>
				<div className="flex items-center mx-auto">
					{loading ? (
						<Ring color="var(--color-blue-500)" size={28} />
					) : (
						<div className="flex items-center gap-2 text-custom-gray-dark">
							<FontAwesomeIcon icon={faFiles} />
							<p className="mr-4 text-sm font-medium">{!destFolder ? 'Select folder' : buttonText}</p>
						</div>
					)}
					<input type="file" ref={fileInputField} onChange={onUpload} value={value} multiple className="hidden" />
				</div>
			</BasicButton>
			{_files?.map((file) => (
				<div key={file.uid} className={`flex items-center py-2 border-b border-gray-300 last:border-b-0 first:pt-4 last:pb-4 w-full`}>
					<div className="w-5/6 text-xs font-medium text-primary">
						<Link href={`${API_URL}/storage/files/${file.uid}/download`}>
							<div className="flex items-center">
								<div className="mr-2">
									<FontAwesomeIcon icon={faCloudDownload} />
								</div>
								<p className="break-all ">{file.filename}</p>
							</div>
						</Link>
					</div>
					<div className="ml-auto text-right cursor-pointer hover:text-red-400" onClick={() => _onRemove(file)}>
						<FontAwesomeIcon icon={faTrashCan} />
					</div>
				</div>
			))}
		</div>
	);
};
