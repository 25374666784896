import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#1A1A1A',
		secondary: '#43bfa9',
		black: '#1A1A1A',
		white: '#F1F0ED',
		login_screen: {
			color: 'text-white',
			title: 'Et ledende ansatteid konsulentselskap med de flinkeste folka',
			statement: '',
		},
		blue: {
			700: '#43bfa9',
			600: '#43bfa9',
			500: '#43bfa9',
			100: '#e9fffb',
		},
		red: {
			500: '#7C3647',
		},
		authimg: 'url("/systek-bg-login.jpg")',
		authlogo: 'url("/systek-auth-logo.png")',
		logosymbol: 'url("/systek-symbol.svg")',
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
