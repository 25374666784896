import { quickactionsAtom } from '@/atoms/quickactions';
import { changeSpace, isSubspace, selectedSpaceAtom, spacesAtom } from '@/atoms/spaces';
import { meAtom } from '@/atoms/users';
import { PopoverNotifications } from '@/features/notifications/components/PopoverNotifications';
import { ITemplate } from '@/features/templates/types';
import { DropdownGeneric } from '@/shared/components/fields';
import { signOut } from '@/utils/api/authentication';
import { parseName } from '@/utils/helpers';
import { faPuzzle } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { ThemingPageButton } from '../features/theming';
import { ConnectionDot } from '../shared/components/socket/ConnectionDot';
import { createLiteTask } from '../utils/api/projects';
import Avatar from './avatar';

export const Header = () => {
	const { contents: quickActions, state } = useRecoilValueLoadable<ITemplate[]>(quickactionsAtom);
	const user: any = useRecoilValue(meAtom);
	const { contents: spaces, state: SpacesState } = useRecoilValueLoadable(spacesAtom);
	const selectedSpace = useRecoilValue(selectedSpaceAtom);
	const router = useRouter();
	const _changeSpace = useSetRecoilState(changeSpace);
	const isSupplier = useRecoilValue(isSubspace);

	const navigateToDashboard = () => {
		router.push(isSupplier ? '/projects' : '/');
	};

	const navigateToTaskFlow = async (template: string) => {
		const task = await createLiteTask();
		router.push({
			pathname: '/tasks/' + task.uid,
			query: {
				t: template,
			},
		});
	};

	const navigateToProfile = () => {
		router.push({
			pathname: '/settings',
			query: {
				t: 'profile',
			},
		});
	};

	const menu = [
		{
			label: 'Your profile',
			onClick: navigateToProfile,
		},
	];

	if (SpacesState === 'hasValue' && spaces?.length > 1) {
		menu.push({
			label: 'Change space',
			onClick: () => _changeSpace(true),
		});
	}

	menu.push({
		label: 'Sign out',
		onClick: () => {
			signOut();
			router.push('/auth/login');
		},
	});

	return (
		<header className="flex h-16 w-full items-center gap-4 bg-[#202020] px-6 py-4">
			<ThemingPageButton />
			<div onClick={navigateToDashboard} className="bg-logoSymbol relative h-6 w-6 cursor-pointer  bg-center bg-no-repeat"></div>
			<Divider />
			<div className="flex flex-1 items-center justify-between">
				<div id="header-element"></div>
				<div className="min-w-fit">
					{state === 'hasValue' && quickActions.length > 0 ? (
						<DropdownGeneric
							collection={quickActions}
							renderItem={({ item }) => (
								<div onClick={() => navigateToTaskFlow(item.uid)} className="cursor-pointer border-b last:border-none">
									<p className="p-3 text-sm font-light text-gray-500 hover:text-blue-500">
										Create <span className="font-medium">{item.name}</span>
									</p>
								</div>
							)}
							button={
								<div className="grid h-10 w-10 cursor-pointer place-content-center rounded-full bg-blue-500 text-white">
									<FontAwesomeIcon icon={faPlus} size="lg" />
								</div>
							}
						/>
					) : isSupplier ? (
						<Popover content="Create tasks with the power of AI - Contact sales" placement="bottom">
							<Link href="/sales">
								<div className="grid h-10 w-10 cursor-pointer place-content-center rounded-full bg-blue-500 text-white opacity-40">
									<FontAwesomeIcon icon={faPlus} size="lg" />
								</div>
							</Link>
						</Popover>
					) : (
						<></>
					)}
				</div>
			</div>
			<Divider />
			<div className="flex w-fit items-center justify-evenly gap-4">
				<PopoverNotifications />
				<Link href="/apps">
					<FontAwesomeIcon className={router.pathname.startsWith('/apps') ? 'text-blue-500' : 'text-gray-400'} icon={faPuzzle} size="lg" />
				</Link>
			</div>
			<Divider />
			<div>
				<DropdownGeneric
					collection={menu}
					renderItem={({ item }) => (
						<div
							onClick={item.onClick}
							className="w-full cursor-pointer border-b py-2 transition-all last:border-none hover:bg-neutral-100"
						>
							<p className="px-2 text-xs font-medium">{item.label}</p>
						</div>
					)}
					button={
						<div className="flex items-center gap-3">
							<ConnectionDot user={user}>
								<Avatar email={user?.email} border={false} size={40} />
							</ConnectionDot>
							<div className="flex flex-col items-start">
								<h6 className="text-sm font-thin text-white">{parseName(user)}</h6>
								<p className="text-xs font-thin text-gray-400">{user?.email}</p>
								<p className="text-xs font-thin text-gray-400">{selectedSpace?.name ?? selectedSpace?.reference}</p>
							</div>
						</div>
					}
				/>
			</div>
		</header>
	);
};

const Divider = () => {
	return <div className="h-full border-r border-gray-600" />;
};
