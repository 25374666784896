// eslint-disable-next-line
export default () => {
	return {
		primary: '#e96b25',
		secondary: '#202020',
		black: '#202020',
		white: '#fff',
		blue: {
			700: '#e96b25',
			600: '#e96b25',
			500: '#e96b25',
			100: '#ffdac6',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/bg-auth-commitment.jpg")',
		authlogo: 'url("/commitment-auth-logo.png")',
		logosymbol: 'url("/commitment-symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'Virksomheter som forstår at forretning er IT, er morgendagens vinnere',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
