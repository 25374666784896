import { fetcher } from '../';

export const createTemplate = async (type, { alias, subject, content }) => {
	return await fetcher(`/templates/${type}`, {
		method: 'POST',
		body: JSON.stringify({ alias, subject, content }),
	});
};

export const getTemplates = async (type) => {
	return await fetcher(`/templates/${type}`);
};

export const getTemplate = async (type, uid) => {
	return await fetcher(`/templates/${type}/${uid}`);
};

export const deleteTemplate = async (uid) => {
	return await fetcher(`/templates/${uid}`, {
		method: 'DELETE',
	});
};

export const patchTemplate = async (uid, { alias, subject, content }) => {
	return await fetcher(`/templates/${uid}`, {
		method: 'PATCH',
		body: JSON.stringify({ alias, subject, content }),
	});
};
