import { InputHTMLAttributes, TextareaHTMLAttributes, forwardRef } from 'react';
interface ITextInputProps {
	label?: string;
	locked?: boolean;
	textarea?: boolean;
	error?: string;
}

type ITextInput = ITextInputProps & (InputHTMLAttributes<HTMLInputElement> | TextareaHTMLAttributes<HTMLTextAreaElement>);

export const TextInput = forwardRef(({ readOnly, locked, label, error, ...props }: ITextInput, ref: any) => {
	const className = `field-input ${props.className}`;

	props.value = props.value || '';
	return (
		<div className="group w-full">
			{label && <p className="text-xs font-medium transition-all duration-300 group-focus-within:text-blue-500">{label}</p>}
			{readOnly || locked ? (
				<p className={props.className}>{props.value}</p>
			) : (
				<>
					{props?.textarea ? (
						<textarea ref={ref} {...(props as TextareaHTMLAttributes<HTMLTextAreaElement>)} className={`field-style ${className}`} />
					) : (
						<>
							<input {...(props as InputHTMLAttributes<HTMLInputElement>)} className={`field-style ${className}`} ref={ref} />
							<p className={`ml-2 text-sm text-red-400 transition-all duration-200 ease-in-out ${error ? 'opacity-100' : 'opacity-0'}`}>
								{error || ''}
							</p>
						</>
					)}
				</>
			)}
		</div>
	);
});

TextInput.displayName = 'TextInput';
