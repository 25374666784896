import { fetcher } from '../../../utils/api';

export const getPages = async () => {
	return await fetcher(`/pages`);
};

export const addPages = async ({ name, settings, path, icon }) => {
	return await fetcher(`/pages`, {
		method: 'POST',
		body: JSON.stringify({
			name,
			settings,
			path,
			icon,
		}),
	});
};

export const addLayoutToPage = async (page, layout) => {
	return await fetcher(`/pages/${page}/layouts/${layout}`, {
		method: 'POST',
	});
};
