import { notificationsAtom } from '@/atoms/notifications';
import { meAtom } from '@/atoms/users';
import { INotification } from '@/features/notifications/types';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'sonner';
import { selectedSpaceAtom } from '../atoms/spaces';
import { usePusher } from '../context/pusher-context';

export const NotificationsProvider = () => {
	const setNotifications = useSetRecoilState(notificationsAtom);
	const me = useRecoilValue(meAtom);
	const spaceSelected = useRecoilValue(selectedSpaceAtom);
	const { subscribe, bind } = usePusher();

	const subscribeChannels = async () => {
		await Promise.all([
			subscribe({ channel: `user.${me.uid}`, name: `User: ${me.firstname}` }),
			subscribe({ channel: `space_user.${me.space_user_uid}`, name: `Space user: ${me.firstname}` }),
			subscribe({ channel: `space.${spaceSelected.uid}`, name: `Space: ${spaceSelected.name}` }),
		]);

		if (spaceSelected.uid) {
			bind({
				event: 'notifications',
				channel: `space_user.${me.space_user_uid}`,
				callback: (data) => {
					promptNotification(data);
					toast(
						<div>
							<h1>New notification</h1>
							<p className="text-gray-custom-dark text-sm" dangerouslySetInnerHTML={{ __html: data.title }} />
						</div>,
						{ position: 'top-right' }
					);
				},
			});
		}
	};

	useEffect(() => {
		if (!me.is_employee) return;
		subscribeChannels();
	}, [me, spaceSelected]);

	const promptNotification = (notification: INotification) => {
		setNotifications((prev) => [notification, ...prev]);
	};

	return null;
};
