import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ILink, fetchLink } from './utils/fetchLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'sonner';

export const Redirection = () => {
	const router = useRouter();

	const [manualRedirect, setManualRedirect] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isPasswordProtected, setIsPasswordProtected] = useState(false);

	const errorHandler = ({ error_code, host }: ILink) => {
		let errorMessage = null;
		switch (error_code) {
			case 'NOT_FOUND':
				errorMessage = 'The link you are trying to access does not exist';
				setManualRedirect(true);
				break;
			case 'NOT_AUTHORIZED':
				errorMessage = 'You are not authorized to access this link';
				toast.error(
					<div className="flex flex-col gap-2">
						<div className="font-bold">Not authorized to access this link. You must be logged in.</div>
						<div className="text-sm">We will redirect you to the platform.</div>
					</div>,
					{
						position: 'top-center',
					}
				);
				setTimeout(() => {
					window.location.replace(`https://${host}`);
				}, 3000);
				return;
				break;
			case 'PASSWORD_REQUIRED':
				errorMessage = 'The link you are trying to access is password protected';
				setIsPasswordProtected(true);
				break;
			default:
				errorMessage = 'Something went wrong. Please try again later.';
				break;
		}

		setErrorMessage(errorMessage);
	};

	const fetchTracking = async (password?: string) => {
		setManualRedirect(false);
		setErrorMessage(null);

		try {
			const link = await fetchLink(router.query.code as string, password);

			if (link.error_code) {
				errorHandler(link);
				return;
			}

			if (window) {
				const queryString = {};
				try {
					const { search, pathname } = new URL('https://s.io' + link.path);

					const searchParams = new URLSearchParams(search);
					// @ts-ignore
					for (const [key, value] of searchParams) {
						queryString[key] = value;
					}

					const formattedQueryString = new URLSearchParams(queryString).toString();

					if (link.host.startsWith(window.location.hostname)) {
						router.push({ pathname: pathname, query: queryString }, undefined, { shallow: true });
					} else {
						window.location.replace(
							`${link.protocol}://${link.host}${pathname}?_f_rt_=${link.token}${
								Object.keys(queryString).length > 0 ? `&${formattedQueryString}` : ''
							}`
						);
					}
				} catch (error) {
					throw new Error(error);
				}
			}
		} catch (error) {
			setErrorMessage(String(error));
			setManualRedirect(true);
		}
	};

	useEffect(() => {
		if (router.asPath.startsWith('/r/')) {
			if (!!router.query.code) fetchTracking();
			else setManualRedirect(true);
		}
	}, [router.query.code]);

	// Add event listener for when the user press enter in the input field
	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const handleEnter = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				manuallyRedirect();
			}
		};
		window.addEventListener('keydown', handleEnter);
		return () => window.removeEventListener('keydown', handleEnter);
	}, [isPasswordProtected, manualRedirect]);

	const manuallyRedirect = () => {
		const input = inputRef.current;
		if (!input) {
			setErrorMessage('Something went wrong. Please try again later.');
			return;
		}

		if (isPasswordProtected) {
			const password = (input as HTMLInputElement).value;
			if (password) {
				fetchTracking(password);
			}
			return;
		}

		if (manualRedirect) {
			const code = (input as HTMLInputElement).value;
			if (code) {
				router.push(`/r/${code}`, undefined, { shallow: true });
			}
		}
	};

	let content = (
		<>
			<div className="text-2xl font-bold animate-pulse">Hang Tight...</div>
			<div className="font-bold text-md">We&apos;re redirecting you to the desired link</div>
		</>
	);

	if (isPasswordProtected) {
		content = (
			<>
				<div className="text-2xl font-bold">The desired link is password protected</div>
				<div className="flex items-center mt-2 font-bold text-md">
					<input
						ref={inputRef}
						className="w-full aria-[errormessage]:border-red-500 px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:bg-white focus:border-gray-500"
						type="text"
						aria-errormessage={errorMessage}
						placeholder="Enter password"
					/>
					<FontAwesomeIcon
						icon={faArrowRight}
						onClick={manuallyRedirect}
						className="w-6 h-6 ml-2 text-gray-500 cursor-pointer hover:animate-wiggle"
					/>
				</div>
				<div className="mb-2 text-red-500">{errorMessage}</div>
			</>
		);
	}

	if (manualRedirect) {
		content = (
			<>
				<div className="text-2xl font-bold">Enter redirection code manually</div>
				<div className="flex items-center mt-2 font-bold text-md">
					<input
						ref={inputRef}
						className="w-full aria-[errormessage]:border-red-500 px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:bg-white focus:border-gray-500"
						type="text"
						aria-errormessage={errorMessage}
						placeholder="Redirection code"
					/>
					<FontAwesomeIcon
						icon={faArrowRight}
						onClick={manuallyRedirect}
						className="w-6 h-6 ml-2 text-gray-500 cursor-pointer hover:animate-wiggle"
					/>
				</div>
				<div className="mb-2 text-red-500">{errorMessage}</div>
			</>
		);
	}

	return (
		<div className="flex flex-col items-center justify-center h-screen gap-2">
			{content}
			<div className="w-40 pb-8 mx-auto bg-center bg-no-repeat bg-contain bg-authLogo" />
		</div>
	);
};
