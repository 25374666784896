declare global {
	interface Window {
		_cio: any;
	}
}

export const customerIOevent = (event: string, data: Record<string, any> = {}, user?: string) => {
	if (window._cio) {
		let existingUser = null;

		if (!!user !== false && typeof user === 'string') {
			existingUser = window._cio._findCustomer();
		}

		data = { ...data, host: window.location.hostname, _event_sender: existingUser };

		if (existingUser) {
			window._cio.identify({
				id: user,
			});
		}

		window._cio.track(event, data);

		if (existingUser) {
			window._cio.identify({
				id: existingUser,
			});
		}
	}
};
