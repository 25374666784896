// eslint-disable-next-line
export default () => {
	return {
		primary: '#000',
		secondary: '#d22328',
		black: '#000',
		white: '#fff',
		blue: {
			700: '#000',
			600: '#000',
			500: '#000',
			100: '#dadada',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/bg-auth-borndigital.jpg")',
		authlogo: 'url("/borndigital_logo.png")',
		logosymbol: 'url("/borndigital_symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'We create and build new solutions',
			statement:
				'All the way from ideas, design and strategy to functional AI, digital solutions, branding and marketing. Delivered as a project, or as on-site consultants.',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
