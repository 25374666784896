import { ImageWithFallback } from '@/utils/ImageWithFallback';
import { cn } from '@/utils/helpers';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

interface IAvatar {
	email?: string;
	text?: string;
	className?: string;
	tooltip?: string | JSX.Element;
	border?: boolean;
	size?: number;
	selected?: boolean;
	onToggle?: () => void;
	spacing_size?: number;
}

export const Avatar = ({
	email = '',
	text,
	className = '',
	tooltip = null,
	border = true,
	size = 44,
	selected,
	onToggle,
	spacing_size = 10,
}: IAvatar) => {
	const initials = email.substring(0, 2);
	const [toggle, setToggle] = useState(selected);

	const toggleHandler = () => {
		if (!onToggle) return;
		setToggle((prev) => !prev);
		onToggle?.();
	};

	useEffect(() => {
		setToggle(selected);
	}, [selected]);

	const avatarStyle = cn(
		'relative flex rounded-full first:!ml-[-2px] group self-center overflow-hidden',
		className,
		onToggle && 'cursor-pointer',
		border && 'border-2 border-white',
		toggle && 'border-2 !border-blue-500'
	);

	const textContent = (
		<p
			style={{ fontSize: size / 2.5 }}
			className="grid w-full h-full m-auto font-bold text-white capitalize bg-blue-500 pointer-events-none select-none place-content-center"
		>
			{text ?? initials.toLowerCase()}
		</p>
	);

	const content = (
		<div style={{ height: size, width: size, marginLeft: -spacing_size }} className={avatarStyle} onClick={toggleHandler}>
			{text ? (
				textContent
			) : (
				<ImageWithFallback
					src={`https://cdn.flextribe.io/avatars/${email}`}
					objectFit="cover"
					className="h-10 max-w-[124px] w-full object-cover"
					fill
					alt=""
					fallback={textContent}
				/>
			)}
		</div>
	);

	return tooltip !== null ? (
		<Tooltip overlayStyle={{ pointerEvents: 'none' }} title={tooltip}>
			{content}
		</Tooltip>
	) : (
		content
	);
};

export default Avatar;
