import { taskFilterAtom } from '@/atoms/filter';
import { selectedSpaceAtom } from '@/atoms/spaces';
import { Icon } from '@/features/icons/components/IconLoader';
import { ImageWithFallback } from '@/utils/ImageWithFallback';
import { getLastOnline } from '@/utils/helpers';
import { faBellExclamation, faCaretUp, faCheck, faChevronLeft, faHourglass } from '@fortawesome/pro-regular-svg-icons';
import { Disclosure, Transition } from '@headlessui/react';
import { Ring } from '@uiball/loaders';
import { useProjects } from 'context/data-wrapper-context';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { StatTag } from '../StatTag';

interface ISideBarProps {
	isSidebarOpen: boolean;
	setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectsSideBar = ({ isSidebarOpen, setIsSidebarOpen }: ISideBarProps) => {
	const { tasks, memoByOwner, templates, isLoading, memoByShared } = useProjects();
	const [filters] = useRecoilState(taskFilterAtom);
	const { contents: selectedSpace } = useRecoilValueLoadable(selectedSpaceAtom);
	const router = useRouter();

	const setRouterQuery = (template: string, type: 'internal' | 'external' | 'shared') => {
		router.push({ pathname: '/projects', query: { ...router.query, type, template } }, undefined, { shallow: true });
	};

	const anyOwnerTasksSinceLastOnline = () => {
		const lastOnline = getLastOnline();
		if (lastOnline) {
			return memoByOwner.some((group) => {
				return group.tasks.some((task: any) => {
					return new Date(task.created).getTime() > new Date(lastOnline).getTime();
				});
			});
		} else {
			return false;
		}
	};

	const minibidTasks = tasks?.filter((task) => task.is_minibid === true);

	return (
		<Transition
			show={isSidebarOpen}
			enter="transition ease-in-out duration-300 transform"
			enterFrom="-translate-x-full"
			enterTo="translate-x-0"
			leave="transition ease-in-out duration-300 transform"
			leaveFrom="translate-x-0"
			leaveTo="-translate-x-full"
		>
			<div className="font-mulish z-0 h-full w-96 overflow-y-auto border-r bg-white">
				<div className="ml-3 mt-6 flex cursor-pointer items-center gap-2" onClick={() => setIsSidebarOpen(false)}>
					<div className="flex h-7 w-7 items-center justify-center rounded-full border-2 bg-gray-200">
						<Icon icon={faChevronLeft} size="xs" />
					</div>
					<span className="text-sm text-gray-600">Close sidebar</span>
				</div>
				<div className="mt-6 border-b pb-6 pl-3">
					<span className="cursor-default text-xl font-bold text-[#252525]">Task overview</span>
				</div>
				{isLoading ? (
					<div className="flex h-full w-full flex-col items-center justify-center">
						<>
							<Ring lineWeight={5} speed={2} color="var(--color-blue-500)" />
						</>
						Loading...
					</div>
				) : (
					templates
						.filter((template) => template.is_minibid === false && template._owner.uid === selectedSpace?.uid)
						.map((template) => (
							<div
								key={template.uid}
								onClick={() => setRouterQuery(template.uid, 'internal')}
								className={`group flex cursor-pointer items-center justify-between border-b px-4 py-4 hover:bg-gray-100 ${
									template.uid === filters.template ? 'bg-gray-100' : ''
								}`}
							>
								<span className="text-sm font-bold text-gray-500">{template.name}</span>

								<StatTag variant="primary">
									<>{template.tasks.filter((task: any) => task._state === 'published' && task._shared === false).length}</>
								</StatTag>
							</div>
						))
				)}
				{memoByOwner.length > 0 && (
					<Disclosure key={'requestFromExternal'}>
						{({ open }) => (
							<>
								<Disclosure.Button className="group flex w-full cursor-pointer items-center justify-between border-b px-4 py-4 hover:bg-gray-100">
									<div className="flex items-center gap-2">
										<Icon
											icon={faCaretUp}
											size="sm"
											className="transition-all duration-200"
											style={open ? { transform: 'rotate(180deg)' } : {}}
										/>
										<span
											className="text-sm font-bold text-gray-500"
											style={open ? { color: 'var(--color-blue-500)' } : { color: 'var(--color-gray-500)' }}
										>
											External requests
										</span>
										{anyOwnerTasksSinceLastOnline() && <Icon icon={faBellExclamation} className="text-red-400" />}
									</div>
									<div className="flex gap-2">
										<StatTag variant="destructive">
											<Icon icon={faHourglass} size="sm" />
											<div>{minibidTasks.filter((task) => !task.interested || task.interested === false).length}</div>
										</StatTag>
										<StatTag variant="confirm">
											<Icon icon={faCheck} size="sm" />
											<div>{minibidTasks.filter((task) => task.interested === true).length}</div>
										</StatTag>
									</div>
								</Disclosure.Button>
								<Disclosure.Panel className=" text-gray-500">
									<>
										{memoByOwner.map((owner) => (
											<div
												key={owner.uid}
												className={` flex cursor-pointer items-center gap-1 px-4  py-4 hover:bg-gray-100 ${
													owner.tasks[0].template.uid === filters.template ? 'bg-gray-100' : ''
												}`}
												onClick={() => setRouterQuery(owner.tasks[0].template.uid, 'external')}
											>
												<div className="w-1/5">
													<ImageWithFallback src={owner.ownerIcon} width={100} height={0} alt="Customer" />
												</div>

												<div className="ml-1 flex w-2/5 items-center gap-1">
													<StatTag variant="destructive">
														<Icon icon={faHourglass} size="sm" />
														<div>
															{owner.tasks.filter((task: any) => !task.interested || task.interested === false).length}
														</div>
													</StatTag>
													<StatTag variant="confirm">
														<Icon icon={faCheck} size="sm" />
														<>{owner.tasks.filter((task: any) => task.interested === true).length}</>
													</StatTag>
												</div>
												{owner.tasks.filter(
													(task: any) => new Date(task.created).getTime() > new Date(getLastOnline()).getTime()
												).length > 0 && (
													<div className="flex w-1/3 items-center gap-1 text-red-400">
														<Icon icon={faBellExclamation} />
														<span className="text-xs">
															{
																owner.tasks.filter(
																	(task: any) =>
																		new Date(task.created).getTime() > new Date(getLastOnline()).getTime()
																).length
															}{' '}
															new requests
														</span>
													</div>
												)}
												<Icon icon="faChevronRight" className="ml-auto" />
											</div>
										))}
									</>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				)}
				{memoByShared.length > 0 && (
					<Disclosure key={'sharedWithYou'}>
						{({ open }) => (
							<>
								<Disclosure.Button className="group flex w-full cursor-pointer items-center justify-between border-b px-4 py-4 hover:bg-gray-100">
									<div className="flex items-center gap-2">
										<Icon
											icon={faCaretUp}
											size="sm"
											className="transition-all duration-200"
											style={open ? { transform: 'rotate(180deg)' } : {}}
										/>
										<span
											className="text-sm font-bold text-gray-500"
											style={open ? { color: 'var(--color-blue-500)' } : { color: 'var(--color-gray-500)' }}
										>
											Shared with you
										</span>
										{anyOwnerTasksSinceLastOnline() && <Icon icon={faBellExclamation} className="text-red-400" />}
									</div>
									<div className="flex gap-2">
										<StatTag variant="primary">
											<div>
												{
													memoByShared
														.map((owner) => owner.templates)
														.flat()
														.map((task) => task.tasks)
														.flat()
														.filter((task) => task._shared === true && task._state === 'published').length
												}
											</div>
										</StatTag>
									</div>
								</Disclosure.Button>
								<Disclosure.Panel className=" text-gray-500">
									<>
										{memoByShared.map((owner) => (
											<Disclosure key={'sharedWithYou'}>
												{({ open }) => (
													<>
														<Disclosure.Button className="group flex w-full cursor-pointer items-center justify-between border-b px-4 py-4 hover:bg-gray-100">
															<div className="flex items-center gap-2">
																<Icon
																	icon={faCaretUp}
																	size="sm"
																	className="ml-2 transition-all duration-200"
																	style={open ? { transform: 'rotate(180deg)' } : {}}
																/>
																<div
																	className="text-sm font-bold text-gray-500"
																	style={
																		open ? { color: 'var(--color-blue-500)' } : { color: 'var(--color-gray-500)' }
																	}
																>
																	<div className="ml-1 w-4/5">
																		<ImageWithFallback
																			src={owner.ownerIcon}
																			width={100}
																			height={0}
																			alt="Customer"
																		/>
																	</div>
																</div>
																{anyOwnerTasksSinceLastOnline() && (
																	<Icon icon={faBellExclamation} className="text-red-400" />
																)}
															</div>
															<div className="flex gap-2">
																<StatTag variant="primary">
																	<div>
																		{
																			owner.templates
																				.map((task) => task.tasks)
																				.flat()
																				.filter(
																					(task) => task._shared === true && task._state === 'published'
																				).length
																		}
																	</div>
																</StatTag>
															</div>
														</Disclosure.Button>
														<Disclosure.Panel className=" text-gray-500">
															<>
																{owner.templates.map((template) => (
																	<div
																		key={template.templateUid}
																		className={` flex cursor-pointer items-center gap-1 px-4 py-4 pl-10 text-sm font-semibold hover:bg-gray-100 ${
																			template.tasks[0].template.uid === filters.template ? 'bg-gray-100' : ''
																		}`}
																		onClick={() => setRouterQuery(template.templateUid, 'shared')}
																	>
																		{template.templateName}
																		{template.tasks.filter(
																			(task: any) =>
																				new Date(task.created).getTime() > new Date(getLastOnline()).getTime()
																		).length > 0 && (
																			<div className="flex w-1/3 items-center gap-1 text-red-400">
																				<Icon icon={faBellExclamation} />
																				<span className="text-xs">
																					{
																						template.tasks.filter(
																							(task: any) =>
																								new Date(task.created).getTime() >
																								new Date(getLastOnline()).getTime()
																						).length
																					}{' '}
																					new requests
																				</span>
																			</div>
																		)}
																		<div className="ml-1 flex w-full justify-end gap-1">
																			<StatTag variant="primary">
																				<>
																					{
																						template.tasks.filter(
																							(task: any) =>
																								task._shared === true && task._state === 'published'
																						).length
																					}
																				</>
																			</StatTag>
																		</div>
																	</div>
																))}
															</>
														</Disclosure.Panel>
													</>
												)}
											</Disclosure>
										))}
									</>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
				)}
			</div>
		</Transition>
	);
};

export default ProjectsSideBar;
