import { TextInput } from '@/shared/components/fields';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { GoogleIcon, MicrosoftIcon } from '../../../components/icons/social-icon';

import { selectedTheme } from '@/atoms/theme';
import { faEnvelope, faEnvelopeOpen, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { useRecoilValue } from 'recoil';
import { Loader } from '../../../components/spinners/loader';
import { getAuthLink, magicLink } from '../../../utils/api/authentication';

function LoginPage() {
	const router = useRouter();

	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [authLinks, setAuthLinks] = useState({ google: '', microsoft: '' });
	const [successMessage, setSuccessMessage] = useState(null);
	const theme = useRecoilValue(selectedTheme);
	const isSubmitDisabled = email === '';

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' && !isSubmitDisabled) {
			submitLogin();
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});

			if (params.error) {
				setError(params.error);
				toast.error(params.error, {
					position: 'top-center',
				});
			}
		}
	}, [router]);

	useEffect(() => {
		setIsLoading(false);
		setAuthLinks({
			google: getAuthLink('google'),
			microsoft: getAuthLink('microsoft'),
		});
	}, []);

	const submitLogin = async () => {
		setIsLoading(true);
		setError(null);
		try {
			await magicLink({ email });
			setSuccessMessage('Magic link is sent');
		} catch (error) {
			setError(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<div className="fixed bg-fixed z-[100] login w-full h-full px-4 py-16 bg-authImg bg-cover"></div>
			<div className="flex absolute w-full z-[101] top-[50%] translate-y-[-50%] items-center">
				<div
					className={`${
						theme?.login_screen?.color ?? 'text-primary'
					} 2xl:w-9/12 sm:w-7/12 lg:w-8/12 2xl:pr-60 2xl:pl-60 lg:pl-10 sm:pl-4 max-sm:hidden`}
				>
					<p className="pr-4 font-light lg:text-6xl sm:text-4xl">{theme?.login_screen?.title}</p>
					<p className="pt-8 text-xl lg:pr-40 md:pr-20">{theme?.login_screen?.statement}</p>
				</div>
				<div className="items-end justify-center ml-auto 2xl:w-3/12 lg:w-4/12 2xl:mr-40 lg:mr-10 md:mr-4 md:w-5/12 max-sm:w-full max-sm:mr-4 max-sm:ml-4">
					<div className="w-full px-10 pt-6 pb-10 mt-16 mb-4 bg-white shadow rounded-xl">
						<div className="h-16 pb-8 mx-auto bg-center bg-no-repeat w-60 bg-authLogo bg-contain"></div>
						{successMessage ? (
							<div className="flex flex-col">
								<div className="flex justify-center mt-4">Check your email inbox for the verification link.</div>
								<div className="flex justify-center mt-4">
									<FontAwesomeIcon icon={faEnvelopeOpen} className="text-blue-600 w-14 h-14" />
								</div>
							</div>
						) : (
							<>
								<div className="flex items-center justify-between w-full pt-5">
									<hr className="w-full bg-gray-400" />
									<p className="px-2 text-xs font-medium leading-4 text-gray-400 uppercase select-none">User</p>
									<hr className="w-full bg-gray-400 " />
								</div>
								<div className="w-full mt-4">
									<TextInput
										value={email}
										onChange={(v) => {
											setEmail(v.target.value);
										}}
										label="Email"
										name="email"
										icon={<FontAwesomeIcon icon={faEnvelope} width={24} height={24} />}
										onKeyDown={handleKeyDown}
									/>
								</div>
								<div className="mt-8">
									{error !== null ? (
										<div className="error-msg">
											<FontAwesomeIcon icon={faExclamationCircle} />
											<div className="pt-[2px] pl-2">
												<span className="font-medium">Woops!</span> {error}
											</div>
										</div>
									) : (
										<></>
									)}
									<button
										role="button"
										className="w-full py-4 text-lg font-medium leading-none text-white bg-blue-600 border rounded-xl hover:opacity-80"
										disabled={isSubmitDisabled || isLoading}
										onClick={submitLogin}
									>
										{isLoading ? (
											<div className="w-20 mx-auto">
												<Loader />
											</div>
										) : (
											`Sign in`
										)}
									</button>
								</div>
								<div className="flex items-center justify-between w-full pt-5">
									<hr className="w-full bg-gray-400" />
									<p className="px-2 text-xs font-medium leading-4 text-gray-400 uppercase select-none">OR</p>
									<hr className="w-full bg-gray-400 " />
								</div>
								<Link href={authLinks.google}>
									<button
										aria-label="Continue with google"
										role="button"
										className="focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-xl border-gray-300 flex items-center w-full mt-10 hover:bg-gray-200"
									>
										<div className="w-6 h-6 fill-primary">
											<GoogleIcon />
										</div>
										<p className="ml-4 text-base font-medium text-gray-700">Continue with Google</p>
									</button>
								</Link>
								<Link href={authLinks.microsoft}>
									<button
										aria-label="Continue with github"
										role="button"
										className="focus:outline-none  focus:ring-2 focus:ring-offset-1 focus:ring-gray-700 py-3.5 px-4 border rounded-xl border-gray-300 flex items-center w-full mt-4 hover:bg-gray-200"
									>
										<div className="w-6 h-6 fill-primary">
											<MicrosoftIcon />
										</div>
										<p className="ml-4 text-base font-medium text-gray-700">Continue with Office 365</p>
									</button>
								</Link>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
