import { ChangeEvent, useEffect, useState } from 'react';

interface IFteCalculator {
	value: number;
	placeholder?: string;
	label?: string;
	readOnly?: boolean;
	onChange(value: number): void;
}

export const FteCalculator = ({ value = 0, placeholder = '', label, readOnly = false, onChange }: IFteCalculator) => {
	const [inputValue, setInputValue] = useState<number>(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		let result = parseInt(e.target.value.replace(/\D/g, ''));
		result = isNaN(result) ? 0 : result;
		setInputValue(result);
		onChange?.(result);
	};

	return (
		<div className="group">
			<p className="text-xs font-medium text-gray-500 group-focus-within:text-blue-500 transition-all duration-300">{label ?? 'FTE'}</p>
			{!readOnly ? (
				<div className="relative w-full">
					<input
						type="text"
						placeholder={placeholder}
						value={inputValue === 0 ? '' : inputValue}
						className="field-input field-style w-full"
						onChange={handleChange}
						maxLength={2}
					/>
					<p className="absolute right-4 top-1/2 -translate-y-1/2 text-sm text-gray-400">
						{(inputValue / 37).toFixed(2).replace(/0{0,2}$/, '')} FT Employee
					</p>
				</div>
			) : (
				<>{inputValue}</>
			)}
		</div>
	);
};
