import { getSpaceFromToken } from '@/api/authentication';
import { meAtom } from '@/atoms/users';
import { faApartment } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import 'dayjs/locale/en';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import { map, uniqBy } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { toast } from 'sonner';
import { mutate } from 'swr';
import { changeSpace, selectedSpaceAtom, spacesAtom } from '../../atoms/spaces';
import { tasksAtom } from '../../atoms/tasks';
import { usePusher } from '../../context/pusher-context';
import LoginPage from '../../pages/auth/login';
import SignUpPage from '../../pages/auth/signup';
import { ImageWithFallback, ORGANISATION_AVATERS } from '../../utils/ImageWithFallback';
import { useSWR } from '../../utils/api';
import { setItem } from '../../utils/browserStorage';
import { dayjsLocaleMapper } from '../../utils/helpers';
import { Loader } from '../spinners/loader';
import { SignedIn } from './SignedIn';
import { SignedOut } from './SignedOut';

const OrganisationProvider = ({ children }) => {
	const user = useRecoilValue(meAtom);

	const [selectedSpace, setSelectedSpace] = useRecoilState(selectedSpaceAtom);

	const [selectedOrganisation, setSelectedOrganisation] = useState<{ uid?: string; name?: string }>({});

	const [_changeSpace, setChangeSpace] = useRecoilState(changeSpace);
	const [loading, setLoading] = useState(true);
	const { contents: spaces, state } = useRecoilValueLoadable(spacesAtom);
	const router = useRouter();

	const getOrganisations = () => {
		return uniqBy(map(spaces, 'organisation'), 'uid');
	};

	const getOrganisationSpaces = (uid) => {
		const orgSpaces = spaces.filter((item) => item.organisation?.uid === uid);
		return orgSpaces.filter((item) => item.is_accessible === true);
	};

	const setSpace = (item, redirect = false) => {
		if (item) {
			setItem('_ft_a_', item.token.token);
			if (redirect) {
				window.location.assign('/');
				return;
			}

			dayjs.locale(dayjsLocaleMapper[item?.locale] ?? 'da');
			setSelectedSpace(item);
			setLoading(false);
			setChangeSpace(false);

			mutate('/me');
		}
	};

	useEffect(() => {
		if (_changeSpace === true && selectedSpace?.organisation) {
			setSelectedOrganisation(selectedSpace?.organisation);
		}
	}, [_changeSpace]);

	useEffect(() => {
		if (state === 'hasValue') {
			let currentSpace = spaces?.find(({ uid }) => uid === getSpaceFromToken());

			if (spaces.length === 1) {
				currentSpace = spaces[0];
			}

			if (currentSpace?.is_accessible === true) {
				setSpace(currentSpace);
				return;
			}

			setSelectedOrganisation(spaces[0].organisation);
			setChangeSpace(true);
			setLoading(false);
		}
	}, [state]);

	useEffect(() => {
		if (user?.uid) {
			if (window._cio) {
				window._cio.identify({
					id: user.space_user_uid,
					email: user.email,
					firstname: user.firstname,
					lastname: user.lastname,
					space: user.space,
					created_at: Math.round(new Date(user.created).getTime() / 1000),
					updated_at: Math.round(new Date(user.updated).getTime() / 1000),
				});

				window._cio.page(router.asPath);
			}
		}
	}, [user]);

	if (loading) return <Loader />;

	const selectSpace = (item) => {
		setSpace(item, true);
	};

	if (_changeSpace) {
		return (
			<div className="absolute left-[50%] top-5 flex w-[600px] translate-x-[-50%] flex-col justify-center">
				<div className="no-scrollbar relative my-5 flex items-center gap-4 overflow-x-auto">
					{getOrganisations()?.map((item, i) => (
						<div
							onClick={() => setSelectedOrganisation(item)}
							className="h-20 min-w-[8rem] cursor-pointer gap-4 rounded-md border-2 border-gray-200 bg-gray-200 bg-opacity-60 p-1 hover:bg-gray-300 aria-selected:border-2 aria-selected:border-green-400"
							aria-selected={selectedOrganisation?.uid === item?.uid}
							key={item?.uid + i}
						>
							<Tooltip title={item?.name ?? ''}>
								<div className="relative flex h-full w-full items-center justify-center">
									<ImageWithFallback
										fill
										alt={item?.name ?? ''}
										fallback={<FontAwesomeIcon size="2x" icon={faApartment} />}
										src={ORGANISATION_AVATERS(item?.uid)}
									/>
								</div>
							</Tooltip>
							{/* <div className="flex-1 text-sm font-medium">{!!item.name ? item.name : item.reference}</div> */}
						</div>
					))}
				</div>
				<div className="select-none">
					<div className="mb-2 flex items-center text-xs font-bold">Spaces</div>
					<div className="grid grid-cols-2 gap-4">
						{getOrganisationSpaces(selectedOrganisation?.uid)?.map((item, i) => (
							<div
								onClick={() => selectSpace(item)}
								className="flex h-10 w-[300px] cursor-pointer items-center gap-4 rounded-md bg-gray-200 bg-opacity-60 px-3 py-8 hover:bg-gray-300"
								key={item.uid + 'space' + i}
							>
								<div className="max-h-8 w-[110px] items-center">
									<ImageWithFallback
										width={0}
										height={0}
										alt={item.name ?? ''}
										fallback={<FontAwesomeIcon icon={faApartment} />}
										src={ORGANISATION_AVATERS(item.uid)}
									/>
								</div>
								<div className="flex-1 text-sm font-medium">{!!item.name ? item.name : item.reference}</div>
							</div>
						))}
					</div>
					<div className="my-4 w-full border"></div>
				</div>
			</div>
		);
	}

	return <>{children}</>;
};

const useAuthValidator = () => {
	const [, setUser] = useRecoilState(meAtom);
	const router = useRouter();
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const space = useRecoilValue(selectedSpaceAtom);

	const { subscribe } = usePusher();

	const { data, error } = useSWR(`/me`, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		refreshInterval: 0,
	});

	useEffect(() => {
		if (data) {
			setIsLoaded(true);
			setIsLoggedIn(true);
			setUser(data);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setIsLoaded(true);
			setIsLoggedIn(false);
			setUser(undefined);
		}
	}, [error]);

	useEffect(() => {
		if (router.query['_ft_c']) {
			try {
				setItem('_ft_a_', JSON.parse(window.atob(router.query['_ft_c'].toString())).token);
				toast.success(`Successfully logged in!`, { position: 'top-center' });
				window.location.assign('/');
			} catch (error) {
				toast.error(error.message);
			}
		}
	}, [router]);

	return { isLoaded, isLoggedIn, space };
};

function AuthenticationProvider({ children }) {
	const { isLoaded, space } = useAuthValidator();
	useRecoilValue(tasksAtom);

	const user = useRecoilValue(meAtom);

	useEffect(() => {
		const setLastOnline = () => {
			const dateTime = new Date().toISOString();
			localStorage.setItem('lastOnline', dateTime);
		};

		window.addEventListener('beforeunload', setLastOnline);

		return () => {
			window.removeEventListener('beforeunload', setLastOnline);
		};
	}, []);

	if (!isLoaded) return <Loader />;
	return (
		<>
			<LocalizationProvider adapterLocale={dayjsLocaleMapper[space?.locale] || 'en'} dateAdapter={AdapterDayjs}>
				<SignedIn>
					<OrganisationProvider>{user?.signed_up === false ? <SignUpPage /> : children}</OrganisationProvider>
				</SignedIn>
				<SignedOut>
					<LoginPage />
				</SignedOut>
			</LocalizationProvider>
		</>
	);
}

export default AuthenticationProvider;
