import NextErrorComponent from 'next/error';
import { signOut } from '../utils/api/authentication';

const Error500Page = () => {
	return (
		<>
			<div
				className="absolute top-0 right-0 px-4 py-2 mt-4 mr-4 text-white bg-blue-500 rounded-md cursor-pointer"
				onClick={() => {
					signOut();
				}}
			>
				Log out
			</div>
			<NextErrorComponent statusCode={500} />
		</>
	);
};

export default Error500Page;
