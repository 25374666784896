import { fetcher } from '@/utils/api';
import { IGlossaryBody } from '../types';

export const getGlossary = async (uid?: string) => {
	return await fetcher(`/glossary${uid ? `/${uid}` : ''}`);
};

export const createGlossary = async (glossary: IGlossaryBody) => {
	return await fetcher('/glossary', {
		method: 'POST',
		body: JSON.stringify({ glossary }),
	});
};

export const updateGlossary = async (glossary: IGlossaryBody) => {
	return await fetcher(`/glossary`, {
		method: 'PUT',
		body: JSON.stringify({ glossary }),
	});
};
