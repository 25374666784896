import { getItem, removeItem } from '../../browserStorage';
import { fetcher } from '../index';

export const getSpaceFromToken = () => {
	const authToken = getItem('_ft_a_');

	return parseJwt(authToken)?.space;
};

export function parseJwt(token) {
	if (!token) {
		return;
	}
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

export const magicLink = ({ email }) => {
	return fetcher('/auth/magic_link', {
		method: 'POST',
		body: JSON.stringify({ email }),
	});
};

export const getAuthLink = (strategy) => {
	if (typeof window !== 'undefined') {
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop as string),
		});
		//@ts-ignore
		return `${process.env['NEXT_PUBLIC_API_URL']}/auth/${strategy}?success_uri=${params.redirect ?? '/'}&error_uri=/auth/login`;
	}
	return '';
};

export const signOut = () => {
	if (window._cio) {
		window._cio.reset();
	}

	removeItem('_ft_a_');
	window.location.assign('/auth/login');
};
