export const useButtonVariant = (variant: string) => {
	const variants = {
		default: 'bg-white border border-gray-300 hover:bg-gray-50 focus:outline-blue-500',
		primary: 'bg-blue-500 opacity-90 hover:opacity-100 text-white transition-all',
		destructive: 'bg-red-400 opacity-90 hover:opacity-100 text-white transition-all',
		confirm: 'bg-green-400 opacity-90 hover:opacity-100 text-white transition-all',
		ghost: 'bg-transparent hover:bg-gray-200 focus:outline-blue-500 transition-all',
	};

	if (!variants[variant]) {
		return null;
	}

	return variants[variant];
};
