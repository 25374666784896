import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';

export default function Modal({ children, open, close }: { children: JSX.Element; open: boolean; close?(): void }) {
	const containerRef = useRef(null);

	const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		close?.();
	};

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog
				ref={containerRef}
				as="div"
				className="relative z-50"
				onClose={() => close?.()}
				onKeyDown={(e) => e.key === 'Escape' && close?.()}
			>
				<Transition.Child
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 h-full w-full bg-black opacity-25" onClick={handleOverlayClick} />
				</Transition.Child>

				<div className="fixed inset-0 top-6 overflow-y-auto">
					<div className="flex justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								{children}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
}
