// eslint-disable-next-line
export default () => {
	return {
		primary: '#3b4c5c',
		secondary: '#0ADE90',
		black: '#000',
		white: '#fff',
		blue: {
			700: '#91be1e',
			600: '#91be1e',
			500: '#91be1e',
			100: '#edf5da',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/ft-bg-login.jpg")',
		authlogo: 'url("/flextribe-logo_black.svg")',
		logosymbol: 'url("/bankdata_symbol.png")',
		login_screen: {
			color: 'text-white',
			title: 'Digitalize the way of working with your external workforce',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
