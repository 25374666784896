import { useRecoilValue } from 'recoil';
import { SOCKET_STATES, socketAtom } from '../../../atoms/socket';
import { cn } from '../../../utils/helpers';
import { Popover } from 'antd';
import { meAtom } from '../../../atoms/users';
import { useEffect, useState } from 'react';
import { isObject, isString } from 'lodash';
import { fetcher } from '../../../utils/api';

export const ConnectionDot = ({
	children,
	user,
	position = 'bottomRight',
}: {
	user?: string | Record<string, any>;
	children?: React.ReactElement;
	position?: 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';
}) => {
	const { state } = useRecoilValue(socketAtom);
	const [_user, setUser] = useState({ online: null });

	useEffect(() => {
		if (isString(user)) {
			try {
				fetcher(`/users/${user}/state`).then((res) => {
					setUser(res);
				});
			} catch (e) {}
		} else if (isObject(user)) {
			setUser(user as { online: boolean });
		}
	}, [user]);

	const getPositionStyling = () => {
		switch (position) {
			case 'bottomLeft':
				return 'bottom-0 left-0';
			case 'topRight':
				return 'top-0 right-0';
			case 'topLeft':
				return 'top-0 left-0';
			case 'bottomRight':
			default:
				return 'bottom-0 right-0';
		}
	};

	const getDotColor = () => {
		switch (state) {
			case SOCKET_STATES.CONNECTED:
				return 'bg-green-500';
			case SOCKET_STATES.CONNECTING:
				return 'bg-yellow-500';
			case SOCKET_STATES.DISCONNED:
			default:
				return 'bg-red-500';
		}
	};

	const getUserDotColor = () => {
		switch (_user?.online) {
			case true:
				return 'bg-green-500';
			case false:
			default:
				return 'bg-red-500';
		}
	};

	const classes = cn('bottom-0 right-0', getPositionStyling(), getUserDotColor());
	const realtimeClasses = cn('bottom-0 right-0', getDotColor());
	return (
		<div className="relative">
			{children}
			<Popover
				trigger={'hover'}
				content={
					<>
						<div className="flex flex-col space-y-2 text-xs">
							<div className="flex items-center space-x-2">
								<div className="w-2.5 h-2.5 bg-green-500 rounded-full" />
								<span>User online</span>
							</div>
							<div className="flex items-center space-x-2">
								<div className={`w-2.5 h-2.5 ${realtimeClasses} rounded-full`} />
								<span>Real-time events</span>
							</div>
						</div>
					</>
				}
				placement="left"
			>
				<div className={`absolute ${classes} w-2.5 h-2.5 border border-inherit rounded-full`} />
			</Popover>
		</div>
	);
};
