import { fetcher } from '@/utils/api';
import { IIntroTemplateBody } from '../types';

export const getIntroTemplates = async () => {
	return await fetcher('/intro_templates');
};

export const getIntroTemplate = async (uid: string) => {
	return await fetcher(`/intro_templates/${uid}`);
};

export const getTaskIntroTemplate = async (template: string) => {
	return await fetcher(`/intro_templates/templates/${template}`);
};

export const getIntroTemplateByTemplateUid = async (template: string) => {
	return await fetcher(`/intro_templates/templates/${template}`);
};

export const createIntroTemplate = async (template: IIntroTemplateBody) => {
	return await fetcher('/intro_templates', {
		method: 'POST',
		body: JSON.stringify(template),
	});
};

export const updateIntroTemplate = async (uid: string, template: IIntroTemplateBody) => {
	return await fetcher(`/intro_templates/${uid}`, {
		method: 'PUT',
		body: JSON.stringify(template),
	});
};

export const deleteIntroTemplate = async (uid: string) => {
	return await fetcher(`/intro_templates/${uid}`, {
		method: 'DELETE',
	});
};
