import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

type IGenericDatePicker = (DatePickerProps<Dayjs> | DateTimePickerProps<Dayjs>) & {
	label?: string;
	readOnly?: boolean;
	showtime?: boolean;
	onChange(date: dayjs.Dayjs | null): void;
};

export const GenericDatePicker = ({ label, value, onChange, className, readOnly = false, showtime, format, ...props }: IGenericDatePicker) => {
	const onChangeHandler = (date: dayjs.Dayjs) => {
		if (dayjs(date).isValid()) {
			onChange?.(date);
		}
	};

	const defaultProps = {
		className: `w-full text-lg ${className} field-style`,
		value: value ? dayjs(value) : null,
		onChange: onChangeHandler,
		slots: {
			openPickerIcon: () => <FontAwesomeIcon className="px-px" icon={faCalendar} size="xs" color="#ccc" />,
		},
		sx: {
			'.MuiInputBase-root': {
				height: 40,
				borderRadius: 2,
				backgroundColor: 'white',
			},
			'.MuiInputBase-input': {
				fontSize: 14,
				paddingTop: 0,
				paddingBottom: 0,
			},
		},
	};

	return (
		<div className="max-w-[550px]">
			<p className="pb-2 text-xs font-medium text-blue-500">{label}</p>
			{readOnly ? (
				<p className="text-sm">{value ? dayjs(value).format(format) : 'No date selected!'}</p>
			) : (
				<>
					{showtime ? (
						<DateTimePicker
							ampm={false}
							{...(props as DateTimePickerProps<Dayjs>)}
							{...defaultProps}
							format={format ?? 'DD/MM/YYYY HH:mm'}
						/>
					) : (
						<DatePicker {...(props as DatePickerProps<Dayjs>)} {...defaultProps} format={format ?? 'DD/MM/YYYY'} />
					)}
				</>
			)}
		</div>
	);
};
