import { allStatuses } from '@/atoms/attributes/statuses';
import { meAtom } from '@/atoms/users';
import Modal from '@/components/Modal';
import { IRoom } from '@/features/chat/types';
import { DeleteIcon, DownloadIcon } from '@/features/storage/components';
import { IFile } from '@/features/storage/types';
import { deleteFiles, shareFiles } from '@/features/storage/utils/api';
import { taskSuppliersCollection } from '@/utils/api/collections';
import { updateNodeContent } from '@/utils/api/nodes';
import { attachFilesToSubtasks, attachFilesToTask, getMinibids } from '@/utils/api/projects';
import { getRoomByRef } from '@/utils/api/rooms';
import { getAttachmentsOnNode } from '@/utils/api/storage';
import { getTemplates } from '@/utils/api/templates';
import { customerIOevent } from '@/utils/customerio';
import { Listbox } from '@headlessui/react';
import { useProjects } from 'context/data-wrapper-context';
import { sha256 } from 'crypto-hash';
import { keyBy, merge, values } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MultiFileUpload } from './MultiFileUpload';

const API_URL = process.env.NEXT_PUBLIC_API_URL;

interface IProgressBar {
	readOnly?: boolean;
	onChange?(value: any): void;
	value?: any;
	task: string;
}
export const ProgressBar = ({ readOnly = false, onChange, value = {}, task }: IProgressBar) => {
	const statuses = useRecoilValue(allStatuses);
	const me = useRecoilValue(meAtom) as any;

	const [selected, setselected] = useState(value?.status || statuses[0].name);
	const [subSelected, setSubSelected] = useState(null);
	const [showModal, setShowModal] = useState('');
	const [suppliers, setSuppliers] = useState([]);
	const [files, setFiles] = useState<IFile[]>([]);
	const [saveContractModal, setSaveContractModal] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const { refetchTasks } = useProjects();

	const selectedStatus = statuses.find((v) => v.name === selected);
	let selectedSubStatus = null;

	useEffect(() => {
		if (task) {
			fetchEmailTemplates();
			fetchSuppliers();
			fetchFiles();
		}
	}, [task]);

	useEffect(() => {
		if (selectedStatus?.subs) {
			setSubSelected(value?.sub_status || selectedStatus?.subs[0].name);
		}
	}, [selected]);

	useEffect(() => {
		setselected(value?.status || statuses[0].name);
	}, [value]);

	const fetchEmailTemplates = async () => {
		const templates = await getTemplates('email');
		setEmailTemplates(templates);
	};

	const fetchSuppliers = async () => {
		const minibid = await getMinibids(task);
		const suppliersWithCandidates = minibid.reduce((acc, cur) => {
			const { candidates } = cur;
			if (candidates.length) {
				acc.push({ ...candidates[0].organisation, numOfCandidates: candidates.length });
			}
			return acc;
		}, []);
		const suppliers = await taskSuppliersCollection(task);
		const merged = merge(keyBy(suppliersWithCandidates, 'uid'), keyBy(suppliers, 'uid'));
		const mergedSuppliers = values(merged);
		setSuppliers(mergedSuppliers);
	};

	const fetchFiles = async () => {
		const files = await getAttachmentsOnNode(task);
		setFiles(files);
	};

	const onChangeHandler = async ({ status, subStatus }: Partial<{ status: string; subStatus: string }>) => {
		if (status) setselected(status);
		if (subStatus) setSubSelected(subStatus);
		onChange?.({ status, sub_status: subStatus });
		await updateNodeContent(task, { offer_status: { status, sub_status: subStatus } });
		setShowModal('');
		setSaveContractModal(false);
		refetchTasks();
	};

	const onDeliver = async ({ won, lost, status }) => {
		onChange?.({ status });
		await updateNodeContent(task, { offer_status: { status } });
		setselected(status);
		won.suppliers.map(async ({ uid, contact_persons }) => {
			const room = await getRoomByRef(uid, task);
			sendEmail(contact_persons, won.template, status);
			sendMessage(room, uid, won.template, won.files);
			if (won.files.length > 0) {
				await Promise.all([attachFilesToSubtasks(won.files, task), shareFiles(won.files, uid)]);
			}
		});
		lost.suppliers.map(async ({ uid, contact_persons }) => {
			const room = await getRoomByRef(uid, task);
			sendEmail(contact_persons, lost.template, status);
			sendMessage(room, uid, lost.template, lost.files);
			if (lost.files.length > 0) {
				await Promise.all([attachFilesToSubtasks(lost.files, task), shareFiles(lost.files, uid)]);
			}
		});
	};

	const sendEmail = async (contact_persons: any, template: any, status: string) => {
		contact_persons.map((contact) => {
			let step = null;
			if (status === 'Tilbud levert til kunden') {
				step = '2';
			} else if (status === 'Vunnet') {
				step = '3';
			}

			if (step !== null) {
				customerIOevent('progress', {
					step,
					contact,
					subject: template.subject,
					content: template.content,
					name: status,
				});
			}
		});
	};

	const sendMessage = async (room: IRoom, supplier: any, template: any, files: string[]) => {
		const timestamp = new Date().toISOString();
		const uid = await sha256(supplier.uid + timestamp);
		const payload = {
			id: uid,
			roomuid: room.uid,
			content: `<strong>${template.subject}</strong><br /><br />${template.content}`,
			files,
			sender: {
				uid: me.uid,
				firstname: me?.firstname,
				lastname: me?.lastname,
				email: me.email,
				icon: supplier.icon,
			},
			email: me.email,
			readby: [me.uid],
			timestamp: timestamp,
		};
		// socket?.emit('message:send', { roomUID: room.uid, payload, space: supplier.uid });
	};

	const onFileUpload = async (files: IFile[]) => {
		const uids = files.map((f) => f.uid);
		await attachFilesToTask(uids, task);
		setUploadedFiles(files);
	};

	const download = async (uid: string) => {
		window.location.href = `${API_URL}/storage/files/${uid}/download`;
	};

	const deleteFile = async (uid: string) => {
		const filtered = files.filter((f) => f.uid !== uid);
		setUploadedFiles(filtered);
		await deleteFiles([uid]);
	};

	if (selectedStatus?.subs) {
		selectedSubStatus = selectedStatus.subs.find((v) => v.name === subSelected);
	}

	return (
		<>
			<div className="relative pt-1">
				<Modal onClose={() => setSaveContractModal(false)} show={saveContractModal} maxWidth="max-w-xl">
					<>
						<div className="pb-4 text-center text-xl font-medium">Arkivering af kontrakt</div>
						<div className="flex flex-col items-center">
							<MultiFileUpload label="" onSuccess={onFileUpload} />
							{uploadedFiles.length > 0
								? uploadedFiles.map((file) => {
										return (
											<div
												key={file.uid}
												className="flex w-full items-center border-b border-gray-300 py-2 first:pt-4 last:border-b-0 last:pb-4"
											>
												<div className="text-primary flex w-full items-center justify-between text-xs font-medium">
													<p className="break-all">{file.filename}</p>
													<div className="flex gap-2">
														<button onClick={() => download(file.uid)}>
															<DownloadIcon />
														</button>
														<button onClick={() => deleteFile(file.uid)}>
															<DeleteIcon />
														</button>
													</div>
												</div>
											</div>
										);
								  })
								: null}
							<button
								disabled={!uploadedFiles.length}
								className={`focus:shadow-outline mt-4 w-full px-4 py-3 focus:outline-none ${
									uploadedFiles.length ? 'button-effect' : 'rounded-xl bg-blue-600 text-white opacity-50'
								}`}
								onClick={() => onChangeHandler({ subStatus: 'Kontrakt arkivert' })}
							>
								Save the contract
							</button>
						</div>
					</>
				</Modal>
				<div className="mb-2 flex items-center">
					<div className="flex-shrink">
						<Listbox
							disabled={readOnly}
							value={selected}
							onChange={(r) => {
								if (r === 'Tilbud levert til kunden' || r === 'Vunnet') {
									setShowModal(r);
								} else {
									onChangeHandler({ status: r });
								}
							}}
						>
							<span
								className="inline-block rounded-full px-2 py-1 text-xs font-semibold capitalize"
								style={{
									background: selectedStatus?.color || 'lightgrey',
								}}
							>
								<Listbox.Button>{selected}</Listbox.Button>
								<Listbox.Options className="absolute z-30 mt-1 max-h-60 overflow-auto rounded-md bg-white py-2 text-xs shadow-lg ring-opacity-5 focus:outline-none">
									{statuses
										.filter((v) => v.name !== selected)
										.filter((v) => v.step === selectedStatus?.toStep)
										.map(({ name, color }, statusIdx) => (
											<Listbox.Option
												key={statusIdx}
												className="relative cursor-pointer bg-white px-4 py-2 hover:bg-blue-600 hover:text-white"
												value={name}
											>
												<div className="flex items-center">
													<div className={`mr-2 table h-2 w-2 rounded-3xl`} style={{ backgroundColor: color }}></div>
													{name}
												</div>
											</Listbox.Option>
										))}
								</Listbox.Options>
							</span>
						</Listbox>
					</div>
					<div className="flex flex-grow items-center justify-end">
						<span
							className="flex text-sm font-medium"
							style={{
								color: selectedStatus?.color || 'grey',
							}}
						>
							{selectedStatus.progress || '0'}%
						</span>
					</div>
				</div>
				<div className="relative mb-4 flex h-2 overflow-hidden rounded border-[1px] bg-white text-xs">
					<div
						style={{ width: `${selectedStatus.progress}%`, background: selectedStatus?.color || 'lightgrey' }}
						className="flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none transition-all duration-1000"
					></div>
				</div>
				{selectedStatus?.subs ? (
					<>
						<div className="mb-2 flex items-center">
							{selectedStatus?.subs ? (
								<div className="flex-shrink">
									<Listbox
										disabled={readOnly}
										value={subSelected}
										onChange={(r) => {
											if (r === 'Kontrakt arkivert') {
												setSaveContractModal(true);
											} else {
												onChangeHandler({ subStatus: r });
											}
										}}
									>
										<span
											className="inline-block rounded-full px-2 py-1 text-xs font-semibold capitalize"
											style={{
												background: selectedSubStatus?.color || 'lightgrey',
											}}
										>
											<Listbox.Button>{subSelected ?? '--'}</Listbox.Button>
											<Listbox.Options className="absolute z-30 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
												{selectedStatus.subs
													// .filter((v) => v.name !== subSelected)
													.map(({ name }, statusIdx) => (
														<Listbox.Option
															key={statusIdx}
															className="relative cursor-pointer select-none px-4 py-2"
															value={name}
														>
															<span className="font-normal">{name}</span>
														</Listbox.Option>
													))}
											</Listbox.Options>
										</span>
									</Listbox>
								</div>
							) : (
								<></>
							)}
							<div className="flex-grow text-right">
								<span
									className="inline-block text-sm font-bold"
									style={{
										color: selectedSubStatus?.color || 'lightgrey',
									}}
								>
									{selectedSubStatus?.progress || '0'}%
								</span>
							</div>
						</div>
						<div className="relative mb-4 flex h-2 overflow-hidden rounded border-[1px] text-xs">
							<div
								style={{ width: `${selectedSubStatus?.progress}%`, background: selectedSubStatus?.color || 'lightgrey' }}
								className="flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none transition-all duration-1000"
							></div>
						</div>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default ProgressBar;
