import { fetcher } from '@/utils/api';
import { INotificationType } from '../types';

export const getNotifications = async () => {
	return fetcher('/notifications');
};

export const updateNotification = async (uid: string, props: Record<string, any>, defaultType: INotificationType) => {
	return fetcher(`/notifications/${uid}`, { method: 'PUT', body: JSON.stringify(props) });
};

export const resolveNotification = async (uid: string): Promise<boolean> => {
	await fetcher(`/notifications/${uid}/resolve`, { method: 'PUT' });
	return true;
};

export const unresolveNotification = async (uid: string): Promise<boolean> => {
	await fetcher(`/notifications/${uid}/unresolve`, { method: 'PUT' });
	return true;
};
