import { IExtendedTemplate } from '@/features/templates/types';
import { atom, noWait, selector } from 'recoil';
import { subtasksCollection } from '../../utils/api/collections';

export const selectedTask = atom<Record<string, any>>({
	key: 'selectedTask',
	default: {},
});

export const selectedTaskPrices = atom<string[]>({
	key: 'selectedTaskPrices',
	default: [],
});

export const _tasksAtom = atom<Record<string, any>[]>({
	key: '_tasksAtom',
	default: selector({
		key: 'tasksAtom/Default',
		get: async () => {
			const tasks = await subtasksCollection();
			return tasks;
		},
	}),
});

let globalTasks: any = [];

export const getGlobalTasks = () => globalTasks;

export const tasksAtom = selector({
	key: 'tasksAtom',
	get: ({ get }) => {
		const { contents: tasks, state } = get(noWait(_tasksAtom));

		if (state !== 'hasValue') {
			return [];
		}
		globalTasks = tasks;
		return tasks;
	},
	set: ({ set }, newValue) => {
		set(_tasksAtom, newValue);
		globalTasks = newValue;
	},
});

export const taskTemplateAtom = atom<IExtendedTemplate | undefined>({
	key: 'taskTemplateAtom',
	default: undefined,
});
