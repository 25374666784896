export const StatTag = ({ children, variant }: { children: JSX.Element | JSX.Element[]; variant: 'default' | 'primary' | 'destructive' | 'confirm' }) => {
	const variants = {
		default: 'bg-white border border-gray-300 hover:bg-gray-50',
		primary: 'bg-blue-500 text-white',
		destructive: 'bg-[#E8925526] text-[#E89255]',
		confirm: 'bg-[#00BF8A26] text-[#00BF8A]',
	};

	return (
		<div className={`text-bold flex h-6 items-center justify-center gap-1 rounded-[5px] px-3 py-1 text-xs ${variants[variant]} `}>{children}</div>
	);
};