import { Breadcrumbs, PlusIcon } from '@/features/storage/components';
import { IFolder, IFolderContent } from '@/features/storage/types';
import { createFolder, getFolderContent, getSubfolders } from '@/features/storage/utils/api';
import { Dialog, Transition } from '@headlessui/react';
import { ChangeEvent, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { FolderIcon } from '../icons/folder';
import XmarkIcon from '../icons/x-mark';

interface IFolderSelector {
	open: boolean;
	close(): void;
	onSelect(folder: IFolder): void;
	folder?: string;
	exclude?: string[];
	destination?: string;
}

export default function FolderSelector({ open, close, onSelect, folder, exclude, destination }: IFolderSelector) {
	const [folders, setFolders] = useState<Omit<IFolderContent, 'files'>>();
	const [foldername, setFoldername] = useState<string>('');
	const [isCreating, setIsCreating] = useState<boolean>(false);
	const [selectedFolder, setSelectedFolder] = useState<IFolder>();

	useEffect(() => {
		if (destination) {
			fetchDestination();
		}
	}, [destination]);

	useEffect(() => {
		if (open) {
			fetchFolders(selectedFolder?.uid);
		}
	}, [open]);

	useEffect(() => {
		if (folder) {
			const found = folders.subfolders.find((f) => f.uid === folder);
			setSelectedFolder(found);
			onSelect(found);
		}
	}, [folders, folder]);

	const fetchDestination = async () => {
		const data = await getFolderContent(destination === 'root' ? undefined : destination);
		onSelect(data);
		setSelectedFolder(data);
	};

	const fetchFolders = async (uid?: string) => {
		const data = await getSubfolders(uid);
		setSelectedFolder(data);
		setFolders(data);
	};

	const addFolder = async (name: string) => {
		const subfolders = name.split('/').filter((sub) => sub);
		let parent: Omit<IFolderContent, 'files'> = folders;
		let createdFolder = null;
		if (subfolders.length > 1) {
			for (const name of subfolders) {
				const { folder, didexist } = await createFolder(name.toString(), parent?.uid);
				createdFolder = folder;
				parent?.subfolders.push(folder);
				parent = { ...folder, parents: [parent], subfolders: [] };
			}
			await fetchFolders(parent.uid);
		} else {
			const { folder, didexist } = await createFolder(name, parent?.uid);
			createdFolder = folder;
			await fetchFolders(folder.uid);
		}
		onSelect(createdFolder);
		setIsCreating(false);
		setFoldername('');
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setFoldername(value);
	};

	const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			addFolder(foldername);
		}
		if (e.key === 'Escape') {
			setFoldername('');
			toggleIsCreating();
		}
	};

	const select = () => {
		onSelect(selectedFolder);
	};

	const toggleIsCreating = () => setIsCreating((prev) => !prev);

	const onBreadCrumbClick = (uid: string) => {
		fetchFolders(uid);
	};

	const renderFolders = (folder: Omit<IFolderContent, 'files'>) => {
		if (exclude && exclude.includes(folder.uid)) {
			return <></>;
		}
		return (
			<FolderItem key={folder.uid} folder={folder} isSelected={folder.uid === selectedFolder?.uid} onSelect={() => fetchFolders(folder.uid)} />
		);
	};

	if (!folders) {
		return <></>;
	}

	return (
		<Transition appear show={open} as={Fragment}>
			<Dialog as="div" className="relative z-[10000]" onClose={close}>
				<Transition.Child
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black opacity-25" />
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-full p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<div className="flex items-center justify-between">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
										Select a folder
									</Dialog.Title>
									<span className="cursor-pointer" onClick={close}>
										<XmarkIcon strokeWidth={1.5} />
									</span>
								</div>
								<Breadcrumbs crumbs={[...folders.parents, folders]} onClick={onBreadCrumbClick} />
								<div className="my-4 overflow-y-scroll max-h-56">{folders?.subfolders?.map(renderFolders)}</div>
								{isCreating ? (
									<div className="flex w-full h-16 p-1 cursor-pointer group">
										<div className={`flex p-4 items-center rounded-lg border-2 border-white`}>
											<FolderIcon className="w-6" />
											<input
												className="ml-2 font-medium"
												type="text"
												value={foldername}
												onChange={onChange}
												onKeyDown={handleKeyDown}
												autoFocus
												onFocus={(e) => e.target.select()}
												onBlur={toggleIsCreating}
											/>
										</div>
									</div>
								) : (
									<div onClick={toggleIsCreating} className="flex w-full h-16 p-1 cursor-pointer group">
										<div className="flex items-center justify-center w-full h-full p-4 border-2 border-blue-500 border-dashed rounded-lg opacity-50 cursor-pointer group-hover:opacity-100">
											<FolderIcon className="w-6 text-blue-500" />
											<PlusIcon className="w-8 h-8 text-blue-500" />
										</div>
									</div>
								)}
								<button
									onClick={select}
									className={`w-full inline-flex justify-center rounded-md border border-transparent mt-4 bg-blue-200 hover:bg-blue-300 text-blue-900 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
								>
									Select
								</button>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
}

const FolderItem = ({ folder, isSelected, onSelect }: { folder: IFolder; isSelected: boolean; onSelect(): void }) => {
	const border = {
		default: 'group-hover:border-blue-500 border-white',
		selected: 'border-blue-500',
	};

	const text = {
		default: 'group-hover:text-blue-500',
		selected: 'text-blue-500',
	};
	return (
		<div
			onClick={onSelect}
			className={`flex flex-shrink-0 w-full h-16 cursor-pointer p-1 group relative ${isSelected ? border.selected : border.default}`}
		>
			<div className={`flex w-full h-full p-4 items-center rounded-lg border-2 ${isSelected ? border.selected : border.default}`}>
				<FolderIcon className={`w-6 ${isSelected ? text.selected : text.default}`} />
				<p className={`font-medium ml-2 ${isSelected ? text.selected : text.default}`}>{folder.name}</p>
			</div>
		</div>
	);
};
