import { downloadFile } from '@/utils/api/storage';
import { faCloudDownload, faEye, faLink, faMicrochipAi, faPen, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTurnDownRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Tooltip } from 'antd';
import moment from 'moment';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Icon } from '../../icons/components/IconLoader';
import { IFile, IFolder } from '../types';
import { updateFilename } from '../utils/api';
import FileOptions from './FileOptions';

interface FileProps {
	file: IFile;
	isSelected?: boolean;
	isSelecting?: boolean;
	isProcessing?: boolean;
	readOnly?: boolean;
	onSelect?(): void;
	onRemove?(files: IFile[], folders: IFolder[]): void;
	onMove?(file: IFile): void;
	onPreview?(file: IFile): void;
	onShare?(file: IFile): void;
	onAiAnalyze?(file: IFile): void;
}

const PREVIEW_MIMETYPES = ['application/pdf', 'image/'];

export function File({
	file,
	isProcessing,
	isSelected,
	isSelecting,
	readOnly,
	onSelect,
	onRemove,
	onMove,
	onPreview,
	onShare,
	onAiAnalyze,
}: FileProps) {
	const [filename, setFilename] = useState<string>(file.filename);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	useEffect(() => {
		setFilename(file.filename);
	}, [file.filename]);

	const toggleEditing = () => setIsEditing((prev) => !prev);

	const renameFile = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setFilename(value);
	};

	const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			saveFilename();
		}
		if (e.key === 'Escape') {
			toggleEditing();
		}
	};

	const saveFilename = async () => {
		setIsEditing(false);
		await updateFilename(file.uid, filename);
	};

	const readOptions: any = [
		{
			title: 'Download',
			icon: faCloudDownload,
			onClick: () => downloadFile(file.uid),
		},
	];
	if (!!onPreview && PREVIEW_MIMETYPES.some((type) => file.mimetype.startsWith(type))) {
		readOptions.push({
			title: 'Preview',
			icon: faEye,
			onClick: () => onPreview(file),
		});
	}

	const writeOptions: any = [
		{
			title: 'Rename',
			icon: faPen,
			onClick: toggleEditing,
		},
		{
			title: 'Analyse with AI',
			icon: faMicrochipAi,
			onClick: () => onAiAnalyze?.(file),
			color: 'text-custom-orange',
		},
	];
	if (!!onShare) {
		writeOptions.push({
			title: 'Share',
			icon: faLink,
			onClick: () => onShare(file),
		});
	}
	if (!!onMove) {
		writeOptions.push({
			title: 'Move',
			icon: faTurnDownRight,
			onClick: () => onMove(file),
		});
	}

	if (!!onRemove) {
		writeOptions.push({
			title: 'Delete',
			icon: faTrashAlt,
			onClick: () => onRemove([file], []),
			color: 'text-red-400',
		});
	}

	const options = file?.readonly || readOnly ? readOptions : [...readOptions, ...writeOptions];

	return (
		<FileOptions disabled={isEditing || isSelecting} options={options}>
			<div
				onClick={isSelecting ? onSelect : null}
				className={`relative h-fit w-56 cursor-pointer rounded-lg border-b-2 border-gray-200 bg-white p-3 shadow-sm shadow-gray-200 hover:border-blue-500`}
			>
				{isProcessing && (
					<div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-gray-200 bg-opacity-50">
						<Icon icon={'faMicrochipAi'} size="2x" className="text-custom-orange animate-pulse" />
					</div>
				)}
				{isEditing ? (
					<div className="flex items-center justify-between">
						<input
							type="text"
							value={filename}
							onChange={renameFile}
							autoFocus
							onFocus={(e) => e.target.select()}
							onBlur={toggleEditing}
							onKeyDown={handleKeyDown}
						/>
					</div>
				) : (
					<div className="flex items-center justify-between">
						<h5 title={filename} className="truncate font-medium">
							{filename}
						</h5>
						<div className="flex items-center gap-2">
							{PREVIEW_MIMETYPES.some((type) => file.mimetype.startsWith(type)) && (
								<Tooltip title="Preview">
									<FontAwesomeIcon onClick={() => onPreview(file)} className="text-gray-400" icon={faEye} size="xs" />
								</Tooltip>
							)}
							{!file.readonly && file?.shared_with?.length > 0 && (
								<Tooltip title="File is shared">
									<FontAwesomeIcon className="text-gray-400" icon={faLink} size="xs" />
								</Tooltip>
							)}
							{isSelecting && <Checkbox checked={isSelected} />}
						</div>
					</div>
				)}
				<p className="truncate text-[10px] text-gray-400">{`Type: ${file.mimetype}`}</p>
				<p className="text-[10px] text-gray-400">{`Uploaded: ${moment(file.created).fromNow()}`}</p>
				{!!file?.owner && file.readonly && <p className="text-[10px] text-gray-400">{`Shared by: ${file.owner}`}</p>}
			</div>
		</FileOptions>
	);
}
