import { IContact, IMessage, IRoom } from '@/features/chat/types';
import { sha256 } from 'crypto-hash';
import { fetcher } from '..';
import { IFile } from '@/features/storage/types';

export const getAllRooms = async (): Promise<IRoom[]> => {
	try {
		return await fetcher(`/chat/rooms`);
	} catch (error) {
		console.error('Failed to get rooms', error);
	}
};

export const getRoomsByRelation = async ({ tasks }: { tasks: string[] }): Promise<IRoom[]> => {
	const query = tasks ? `?tasks=${tasks.join(',')}` : '';

	try {
		return await fetcher(`/chat/rooms${query}`);
	} catch (error) {
		console.error('Failed to get rooms', error);
	}
};

export const getRoomByRef = async (uid: string, ref: string): Promise<IRoom> => {
	try {
		return await fetcher(`/chat/contacts/${uid}/rooms/reference/${ref ? ref : 'global'}`);
	} catch (error) {
		console.error('Failed to get contact room by ref', error);
	}
};

export const getContactsByRef = async (ref: string): Promise<IContact[]> => {
	try {
		return await fetcher(`/chat/contacts/reference/${ref ? ref : 'global'}`);
	} catch (error) {
		console.error('Failed to get contacts by ref', error);
	}
};

export const getMessages = async (uid: string, contact: string): Promise<IMessage[]> => {
	try {
		return fetcher(`/chat/contacts/${contact}/rooms/${uid}/messages`);
	} catch (error) {
		console.error('Failed to get messages', error);
	}
};

export const getUnreadMessages = async (user: string): Promise<IMessage[]> => {
	try {
		return fetcher(`/chat/unread/${user}`);
	} catch (error) {
		console.error('Failed to get messages', error);
	}
};

export const getRoomById = async (uid: string): Promise<IRoom> => {
	try {
		return await fetcher(`/chat/rooms/${uid}`);
	} catch (error) {
		console.error('Failed to fetch room by id', error);
	}
};

export const addParty = async (uid: string, user: { email?: string; uid?: string }): Promise<{ success: boolean }> => {
	try {
		return await fetcher(`/chat/rooms/${uid}/party`, {
			method: 'POST',
			body: JSON.stringify({ user }),
		});
	} catch (error) {
		console.error('Failed to add party to chatroom', error);
	}
};

export const uploadFilesToRoom = async (uid: string, files: FormData): Promise<IFile[]> => {
	try {
		return await fetcher(`/chat/rooms/${uid}/files`, {
			method: 'POST',
			body: files,
		});
	} catch (error) {
		console.error('Failed to upload files to chatroom', error);
	}
};

export const markMessageAsRead = async (uid: string, room: string): Promise<void> => {
	try {
		return await fetcher(`/chat/rooms/${room}/messages/${uid}`, {
			method: 'PUT',
		});
	} catch (error) {
		console.error('Failed to mark message as read', error);
	}
};

export const removeParty = async (uid: string, user: { email?: string; uid?: string }): Promise<void> => {
	try {
		return await fetcher(`/chat/rooms/${uid}/party`, {
			method: 'DELETE',
			body: JSON.stringify({ user }),
		});
	} catch (error) {
		console.error('Failed to remove party from chatroom', error);
	}
};

export const deleteFileFromRoom = async (uid: string, file: string): Promise<void> => {
	try {
		return await fetcher(`/chat/rooms/${uid}/files/${file}`, {
			method: 'DELETE',
		});
	} catch (error) {
		console.error('Failed to delete file from chatroom', error);
	}
};
