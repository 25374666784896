import _default from './default';

// eslint-disable-next-line
export default () => {
	return {
		..._default,
		primary: '#333',
		secondary: '#B7DEBD',
		black: '#333',
		white: '#F1F0ED',
		login_screen: {
			color: 'text-primary',
			title: 'Makers of a sustainable future',
			statement:
				'Vi støtter kunder i den digitale transformasjonen, forenkler menneskers hverdag og skaper sikre og innovative løsninger med fokus på bærekraft.',
		},
		blue: {
			700: '#4b6454',
			600: '#4B6455',
			500: '#4b6454',
			100: '#E4E1DB',
		},
		red: {
			500: '#7C3647',
		},
		authimg: 'url("/knowit-bg.jpg")',
		authlogo: 'url("/knowit-logo.svg")',
		logosymbol: 'url("/knowit-symbol.svg")',
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
