import { fetcher } from '../../../utils/api';

export type ILink = {
	host: string;
	path: string;
	token: string;
	protocol: string;
	password_required?: boolean;
	error_code?: string;
};

export const fetchLink = async (code: string, password?: string): Promise<ILink> => {
	return await fetcher(`/links/${code}?password=${password}`);
};
