import { selectedSpaceAtom } from '@/atoms/spaces';
import { CURRENCIES, DEFAULT_CURRENCY, DEFAULT_LOCALE } from '@/utils/constants';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import CurrencyInput, { formatValue as _formatValue, CurrencyInputProps } from 'react-currency-input-field';
import { useRecoilValue } from 'recoil';
import { DropdownGeneric } from '../fields';
import { moneyConversion, moneyDeconversion } from '../../../utils/helpers';
import { roundToThreeDecimals } from '../../utils/helper';

type MoneyInputProps = {
	value?: any;
	onChange?: (result) => void;
	className?: string;
	label?: string;
	disableCurrencySelection?: boolean;
	renderCurrencyContainer?: boolean;
};

export const formatMoneyInputs = ({
	value,
	currency,
	locale,
	space,
}: MoneyInputProps['value'] & { space?: Record<string, any>; locale?: string; label?: string }) => {
	currency = currency ?? space?.currency ?? DEFAULT_CURRENCY;
	value = value ?? 0;
	locale = locale ?? space?.locale ?? DEFAULT_LOCALE;

	return { value, currency, locale };
};

const convertToString = (value, deconversion = true) => {
	if (deconversion) value = moneyDeconversion(Number(value));
	return Number(roundToThreeDecimals(value)).toString();
};

export const formatValue = (value: MoneyInputProps['value'], deconversion = true) => {
	let { value: _value, currency, locale } = formatMoneyInputs(value);

	return _formatValue({
		value: convertToString(_value, deconversion),
		intlConfig: { locale, currency },
	});
};

export const MoneyInput = ({
	onChange,
	className,
	label,
	value,
	disableCurrencySelection = false,
	renderCurrencyContainer = true,
}: MoneyInputProps) => {
	const space = useRecoilValue(selectedSpaceAtom);
	const { value: _value, currency, locale } = formatMoneyInputs({ ...value, space });
	const [initialized, setInitialized] = useState(false);

	const [inputValue, setInputValue] = useState<string>(convertToString(_value));

	const [valueFloat, setValueFloat] = useState<number>(Number(_value));
	const [_currency, setCurrency] = useState<string>(currency);

	useEffect(() => {
		if (_value !== inputValue) {
			setInputValue(convertToString(_value));
		}
		if (currency !== _currency) {
			setCurrency(currency);
		}
	}, [_value, currency]);

	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			return;
		}
		onChange?.({ value: valueFloat, currency: _currency, locale });
	}, [valueFloat, _currency]);

	const handleChange: CurrencyInputProps['onValueChange'] = (value, _, values) => {
		setValueFloat(moneyConversion(values.float));
		setInputValue(value);
	};

	return (
		<>
			{label && <p className="w-full text-xs font-medium text-gray-500">{label}</p>}
			<div className="relative flex items-center justify-between h-full gap-2">
				<CurrencyInput
					value={inputValue}
					className={className + ' w-full'}
					onValueChange={handleChange}
					intlConfig={{ locale, currency: _currency }}
					disableAbbreviations={true}
					decimalsLimit={3}
				/>
				{renderCurrencyContainer && (
					<div className="text-xs text-gray-400">
						{disableCurrencySelection ? (
							<>{_currency}</>
						) : (
							<DropdownGeneric
								collection={CURRENCIES}
								renderItem={({ item }) => (
									<div onClick={() => setCurrency(item)} className="px-2 py-1 rounded-md cursor-pointer hover:bg-gray-100">
										{item}
									</div>
								)}
								button={
									<div className="flex items-center gap-1">
										<p>{_currency}</p>
										<FontAwesomeIcon icon={faChevronDown} />
									</div>
								}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};
