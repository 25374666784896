import { selectedSpaceAtom } from '@/atoms/spaces';
import { useCallback, useEffect } from 'react';
import { default as ReactPhoneInput, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useRecoilValueLoadable } from 'recoil';

interface IPhoneInput {
	label?: string;
	value: string;
	onChange?: (value: string) => void;
	isValid?: boolean;
	setIsValid?: (isValid: boolean) => void;
	disabled?: boolean;
	className?: string;
}

export const PhoneInput = ({ label, value, onChange, isValid, setIsValid, disabled, className }: IPhoneInput) => {
	const browserLocale = (navigator.language || navigator.languages[0]).substring(0, 2);
	const space = useRecoilValueLoadable(selectedSpaceAtom);
	const { locale } = space.state === 'hasValue' ? space.contents : { locale: browserLocale.split('-')[0] };

	const errorMessage = setIsValid && !isValid ? <p className="text-xs text-red-500">Invalid phone number</p> : null;

	const checkPhoneNumber = useCallback(() => {
		if (value && value !== '') {
			setIsValid(isPossiblePhoneNumber(value) || isValidPhoneNumber(value));
		} else {
			setIsValid(true);
		}
	}, [value]);

	useEffect(() => {
		if (!setIsValid) return;
		checkPhoneNumber();
	}, [value]);

	return (
		<div>
			{label && <p className="flex w-full flex-col flex-wrap pb-[1px] text-xs font-medium text-gray-500">{label}</p>}
			<ReactPhoneInput className={className} disabled={disabled} value={value} onChange={onChange} country={locale} />
			{errorMessage}{' '}
		</div>
	);
};
