import { Popover } from 'antd';
import { isString } from 'lodash';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { BasicButton } from '../../../components/buttons/BasicButton';

interface IColorPickerInput {
	color?: string | { alpha: string; green: string; red: string; blue: string };
	onSelect?(color: string | { a: string; g: string; r: string; b: string }): void;
	button?({ open }: { open(): void }): JSX.Element;
	buttonText?: string;
	format?: 'hex' | 'rgb' | 'raw';
}

export const ColorPickerInput = ({ color, onSelect, button, buttonText = 'Select color', format }: IColorPickerInput) => {
	const [_color, setColor] = useState<string>();
	const [_open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!isString(color) && color !== undefined) {
			color = `rgba(${color.red},${color.green},${color.blue},${color.alpha})`;
		}
		setColor(color as string);
	}, [color]);

	const handleSelect = (result: any) => {
		let color;

		const { rgb, hex } = result;

		switch (format) {
			case 'hex':
				color = hex;
				break;
			case 'rgb':
				color = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
				break;
			case 'raw':
				color = rgb;
				break;
			default: {
				const { rgb } = result;
				color = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
				break;
			}
		}
		setColor(color);
	};

	const toggleOpen = () => {
		setOpen((p) => !p);
		if (!_open) return;

		if (color === undefined) return;
		if (color === _color) return;

		onSelect?.(_color);
	};

	return (
		<>
			<Popover
				placement="right"
				trigger={'click'}
				// open={_open}
				onOpenChange={toggleOpen}
				content={
					<div className="flex flex-col gap-2">
						<SketchPicker color={_color} onChange={handleSelect} />
					</div>
				}
			>
				{button ? (
					button({ open: toggleOpen })
				) : (
					<BasicButton className="flex w-full items-center gap-2 text-xs font-medium" onClick={toggleOpen}>
						<div style={{ backgroundColor: _color }} className="h-3 w-3 rounded-full border border-dotted border-black" />
						<span>{buttonText}</span>
					</BasicButton>
				)}
			</Popover>
		</>
	);
};
