import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFolderContent } from '../types';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export function Breadcrumbs({ crumbs, onClick }: { crumbs: IFolderContent['parents']; onClick(uid: string): void }) {
	if (crumbs?.length === 0) {
		return <></>;
	}

	const getTime = (date: string) => {
		return new Date(date).getTime();
	};

	const sortedCrumbs = crumbs?.sort((a, b) => getTime(a.created) - getTime(b.created));

	return (
		<div className="flex">
			{sortedCrumbs?.map((crumb, i) => (
				<button onClick={() => onClick(crumb?.uid)} key={i} className="flex items-center group">
					<p className="text-xs font-medium cursor-pointer group-hover:text-blue-500">{`${crumb?.name}`}</p>
					{i !== crumbs?.length - 1 && <FontAwesomeIcon icon={faChevronRight} className="text-xs mx-2 group-hover:text-blue-500" />}
				</button>
			))}
		</div>
	);
}
