// eslint-disable-next-line
export default () => {
	return {
		primary: '#FF7A0C',
		secondary: '#000',
		black: '#000',
		white: '#fff',
		blue: {
			700: '#FF7A0C',
			600: '#FF7A0C',
			500: '#FF7A0C',
			100: '#FFE5D2',
		},
		red: {
			500: '#a54242',
		},
		authimg: 'url("/bg-auth-jpro.jpg")',
		authlogo: 'url("/jpro-logo.svg")',
		logosymbol: 'url("/jpro-symbol.svg")',
		login_screen: {
			color: 'text-white',
			title: 'Vi utvikler IT-Norges fremtid',
			statement: '',
		},
		tutorials: {
			minibid: {
				video: 'https://demo.arcade.software/LJIcbxLfQ3Z8n2kd5qE5?embed',
				description: '',
			},
		},
	};
};
