import { FilterValue } from 'antd/lib/table/interface';
import { atom, selector } from 'recoil';
import { selectedSpaceAtom } from '../spaces';

interface IProjectFilters {
	template: string;
	typeOfTemplate: 'shared' | 'internal' | 'external';
	configs: Record<
		string,
		{
			columns: Record<string, FilterValue>;
			page: number;
			state: string;
		}
	>;
	space: string;
}

export const DEFAULT_PROJECT_FILTERS: IProjectFilters = {
	template: '',
	typeOfTemplate: 'internal',
	configs: {},
	space: '',
};

export const taskFilterAtom = atom<IProjectFilters>({
	key: 'taskFilterAtom',
	default: selector({
		key: 'taskFilterAtom/default',
		get: ({ get }) => {
			const space = get(selectedSpaceAtom);
			return {
				...DEFAULT_PROJECT_FILTERS,
				space: space?.uid ?? '',
			};
		},
	}),
	effects: [
		({ setSelf }) => {
			if (localStorage.getItem('project_filters')) {
				setSelf(JSON.parse(localStorage.getItem('project_filters')!));
			} else {
				localStorage.setItem('project_filters', JSON.stringify(DEFAULT_PROJECT_FILTERS));
			}
		},
		({ onSet }) => {
			onSet((newValue) => {
				localStorage.setItem('project_filters', JSON.stringify(newValue));
			});
		},
	],
});
